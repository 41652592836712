/* eslint-disable max-lines-per-function */
import React, { ChangeEvent, useEffect } from 'react';
import { State, useHookstate } from '@hookstate/core';
import { Button, Collapse, Grid, IconButton, InputAdornment, OutlinedInput, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import FilterAutocompleteType from '~/components/FilterAutocompleteType';
import FilterAutocompleteSubtypesByType from '~/components/FilterAutocompleteSubtypeByType';
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod';
import FilterAutocompleteCategory from '~/components/FilterAutocompleteCategory';
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups';
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies';
import SelectReportType from '~/components/FilterSelectReportType';
import FilterSwitchOtherDispatchsGroup from '~/components/FilterSwitchOtherDispatchsGroups';
import { LoadingButton } from '@mui/lab';
import FilterAutocompleteCity from '~/components/FilterSelectAutocompleteCity';
import EntryOriginSelect from '~/features/Entry/components/EntryOriginSelect';
import FilterAutocompleteOperation from '~/components/FilterAutocompleteOperation';
import FilterAutocompleteMultipleClosingStatus from '~/components/FilterAutocompleteMultipleClosingStatus';
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet';
import InputOccurrenceNumber from '~/components/InputOccurrenceNumber';
import { DispatchSearchBox } from '~/components/DispatchSearchBox';
import Cancel from '@mui/icons-material/Cancel';
import FilterAutocompleteOperationUnit from '~/components/FilterAutocompleteOperationUnit';
import FilterAutocompleteDevice from '~/components/FilterAutocompleteDevice';
import InputMask from 'react-input-mask'
import { getOnlyNumbersInString } from '~/utils/strings';
import FilterAutocompleteClosedStatusClass from '~/components/FilterAutocompleteClosedStatusClass';
import FilterAutocompleteStatus from '~/components/FilterAutoCompleteStatus';
import FilterCheckboxQualifiers from '~/components/FilterCheckboxQualifiers';
import SelectConsummatedAndAttempted from '~/components/FilterSelectConsummatedAndAttempted';
import useSystemState from '~/features/System/stores/SystemState';
import FilterSwitch from '~/components/FilterSwitch';
import userUserState from '~/features/User/stores/UserState'
import FilterAutoCompleteTypesWithCategory from '~/components/FilterAutoCompleteTypesWithCategory';
import FilterAutocompleteSubtypesWithCategory from '~/components/FilterAutocompleteSubtypesWithCategory';
import FilterKeyword from '~/features/Report/components/FilterKeyword';

interface ManagementFiltersProps {
  readonly loading: State<boolean>
  readonly categoryId: State<number | null>;
  readonly deviceId: State<number | null>;
  readonly groupId: State<readonly number[]>;
  readonly agencyIds: State<readonly number[]>;
  readonly closedStatusIds: State<readonly number[]>;
  readonly initialPeriod: State<string>;
  readonly typeIds: State<number[] | []>;
  readonly subtypeIds: State<number[] | []>;
  readonly refTypeIds?: State<number[] | []>
  readonly hidden?: boolean;
  readonly lockFields: boolean;
  readonly disabledSendButton: () => boolean
  readonly disabledReportType: boolean;
  readonly labelButton: State<string>;
  readonly withSolvedThirdParty: State<boolean>;
  readonly reportType: State<string>;
  readonly cityId?: State<number | null>;
  readonly comments?: State<string>
  readonly includeAllWords: State<boolean>

  readonly searchButton: () => void;
  readonly clearFilters: () => void;

  readonly onStartDateChange: (value: string | null) => void;
  readonly onFinishDateChange: (value: string | null) => void;
  readonly onPeriodChange: (value: string) => void;

  readonly entryOriginId?: State<number | null>;
  readonly entryOriginStation: State<number | null>;
  readonly operationId: State<number | null>;
  readonly groupSetId: State<number | null>;
  readonly occurrenceNumber?: State<string | null>;
  readonly dispatchId?: State<number | null>;
  readonly code?: State<string | null>;
  readonly location?: State<string | null>;
  readonly operationUnitId?: State<number | null>;
  readonly requester: State<string | null>;
  readonly phone: State<string | null>;
  readonly statusId: State<number | null>;
  readonly closedStatusClassId?: State<number | null>;
  readonly qualifiersSelected?: State<number[]>;
  readonly clearState?: State<boolean>
  readonly consummatedAndAttempted?: State<string | null>
  readonly onlyToClerk: State<boolean>
}

export default function ManagementFilters({ withSolvedThirdParty, disabledReportType, labelButton, reportType, lockFields, closedStatusIds, deviceId, groupId, agencyIds, initialPeriod, onFinishDateChange, onPeriodChange, onStartDateChange, typeIds, subtypeIds, refTypeIds, categoryId, hidden, clearFilters, searchButton, loading, cityId, entryOriginId, entryOriginStation, operationId, groupSetId, occurrenceNumber, dispatchId, code, location, operationUnitId, requester, phone, statusId, closedStatusClassId, qualifiersSelected, clearState, consummatedAndAttempted, onlyToClerk, comments, includeAllWords, disabledSendButton }: ManagementFiltersProps) {
  const { translate } = useLang();
  const itemTypeRemoved = useHookstate(false)
  const itemSubtypeRemoved = useHookstate(false)
  const isOpen = useHookstate(true)
  const maskCelPhone = '(99) 99999-9999'
  const maskPhone = '(99) 9999-9999'
  const mask = useHookstate(maskCelPhone)
  const phoneValue = phone.get() == null ? '' : `${phone.get()}`
  const closedStatusClassLock = lockFields
    ? lockFields
    : statusId.get() != null && statusId.get() != 8
  const { systemConfig } = useSystemState()
  const { verifyPermission } = userUserState()

  useEffect(() => {
    if (phoneValue.length <= 9) {
      mask.set(maskCelPhone)
    }
  }, [phoneValue])

  useEffect(() => {

  }, [])



  const defineMask = (number) => {
    if (number <= 10) {
      mask.set(maskPhone)
    } else {
      mask.set(maskCelPhone)
    }
  }

  
  return (
    <Collapse in={hidden}>
      <Grid
        container
        xs={12}
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          paddingX: 2,
          paddingBottom: 2,
        }}
      >
        <Grid container direction={'row'}>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <InputOccurrenceNumber
              occurrence={occurrenceNumber}
              onOccurrenceChange={(event: ChangeEvent<HTMLInputElement>) => {
                occurrenceNumber?.set(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <DispatchSearchBox
              isOpen={isOpen}
              isValid={true}
              notOnlyVisibles={true}
              dispatchId={dispatchId}
              dispatchValue={code}
              isReport={true}
              size="small"
              placeholder="Nº Occurrence"
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteAgencies
              disabled={lockFields}
              agenciesId={agencyIds.get()}
              onAgenciesChange={(agency) => {
                labelButton.set(translate('Search'))
                agencyIds.set(agency)
                groupId.set([])
              }} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDispatchGroupSet
              disabled={lockFields}
              dispatchGroupSetId={groupSetId?.get() ?? undefined}
              onGroupDispatchSetChange={(group) => {
                groupSetId?.set(group)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDispatchsGroups
              disabled={lockFields}
              agencyId={agencyIds.get() ?? undefined}
              dispatcheGroups={groupId.get() ?? undefined}
              onGroupDispatchChange={(group) => {
                groupId.set(group)
                deviceId.set(null)
                labelButton.set(translate('Search'))
              }}
              groupSetId={groupSetId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteCategory
              disabled={lockFields}
              categoryId={categoryId.get()}
              onCategoryChange={(category) => {
                categoryId.set(category)
                // typeIds.set([])
                labelButton.set(translate('Search'))
              }} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutoCompleteTypesWithCategory
              disabled={lockFields}
              categoryId={categoryId.get()}
              typeId={typeIds.get()}
              subtypes={subtypeIds?.get() ?? []}
              refTypeIds={refTypeIds}
              itemTypeRemoved={itemTypeRemoved}
              itemSubtypeRemoved={itemSubtypeRemoved}
              onTypeChange={(type) => { typeIds.set(type) }}
              onSubtypesChange={(subtype) => { subtypeIds?.set(subtype) }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteSubtypesWithCategory
              disabled={lockFields}
              subtypes={subtypeIds?.get() ?? []}
              typeId={typeIds.get()}
              categoryIds={categoryId.get()}
              refTypeIds={refTypeIds}
              itemTypeRemoved={itemTypeRemoved}
              itemSubtypeRemoved={itemSubtypeRemoved}
              onTypeChange={(refId) => { typeIds.set(refId) }}
              onSubtypesChange={(subtype) => { subtypeIds?.set(subtype) }}
            />
            {/* <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                <FilterAutocompleteType
                  disabled={lockFields}
                  categoryId={categoryId.get() ?? undefined}
                  typeId={typeId.get()}
                  onTypeChange={(type) => {
                    labelButton.set(translate('Search'))
                    typeId.set(type?.id as number ?? null)
                  }} />
              </Grid> */}
            {/* <FilterAutocompleteSubtypesByType
              disabled={lockFields}
              hasType
              typeId={typeIds.get()}
              onSubtypeChange={(subtype) => {
                labelButton.set(translate('Search'))
                subtypeIds?.set(subtype?.id ?? null)
              }} /> */}
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteCity
              disabled={lockFields}
              onCityChange={(city) => {
                labelButton.set(translate('Search'))
                cityId?.set(city ? city : null)
              }}
              cityId={cityId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <OutlinedInput
              fullWidth
              id="outlined-search"
              value={location?.get() ?? null}
              size="small"
              placeholder={translate('Location')}
              onChange={(event) => {
                location?.set(event.target.value)
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle clear search location"
                    onClick={() => {
                      location?.set('')
                    }}
                    disabled={location?.get()?.length ? false : true}
                    edge="end"
                  >
                    <Cancel fontSize="small" />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteOperation
              disabled={lockFields}
              onOperationChange={(operation) => {
                operationId?.set(operation)
                labelButton.set(translate('Search'))
              }}
              operationId={operationId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteOperationUnit
              disabled={lockFields}
              onOperationUnitChange={(operationUnit) => {
                operationUnitId?.set(operationUnit)
                deviceId?.set(null)
              }}
              operationUnitId={operationUnitId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteDevice
              onDeviceChange={(device) => {
                deviceId?.set(device)
              }}
              deviceId={deviceId?.get()}
              disabled={lockFields}
              operationUnitId={operationUnitId?.get()}
              groupDispatchs={groupId?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <EntryOriginSelect
              isValid={true}
              isInvalidRequest={false}
              originId={entryOriginId}
              originStation={entryOriginStation.get()}
              hasValueDefault={false} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <TextField
              fullWidth
              disabled={lockFields}
              label={translate('Requester')}
              size="small"
              value={requester?.get() ?? ''}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                requester?.set(event.target.value)
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <InputMask
              mask={mask.get()}
              value={phoneValue}
              onBlur={() => defineMask(phoneValue.length)}
              maskChar=""
              onChange={(changeEvent: ChangeEvent<HTMLInputElement>) => {
                phone.set(getOnlyNumbersInString(changeEvent.target.value))
              }}
            >
              <TextField
                fullWidth
                label={translate('Requester Phone')}
                size="small"
                value={phone.get() ?? ''}
              />
            </InputMask>
          </Grid>
          {/* <Grid item xs={1.5} sx={{ pl: 1, pt: 1 }}>
            <SelectReportType
              disabled={disabledReportType}
              valueDefault={reportType.get()}
              onReportTypeChange={(type) => {
                labelButton.set(translate('Search'))
                reportType.set(type)
              }}
            />
          </Grid> */}

          <FilterSelectPeriod
            disabled={lockFields}
            onStartDateChange={(value) => onStartDateChange(value?.toJSON() ?? null)}
            onFinishDateChange={(value) => onFinishDateChange(value?.toJSON() ?? null)}
            onPeriodChange={onPeriodChange}
            period={initialPeriod.get()}
          />
          <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
            <FilterAutocompleteStatus
              disabled={lockFields}
              onStatusChange={(status) => {
                statusId?.set(status)
                closedStatusClassId?.set(null)
              }}
              statusId={statusId.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pt: 1, pl: 1 }}>
            <FilterAutocompleteClosedStatusClass
              disabled={closedStatusClassLock}
              closedStatusClassId={closedStatusClassId?.get()}
              onClosedStatusClassChange={(closedStatusClass) => {
                closedStatusClassId?.set(closedStatusClass)
                closedStatusIds?.set([])
                labelButton?.set(translate('Search'))
              }}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterAutocompleteMultipleClosingStatus
              disabled={closedStatusClassLock}
              closedStatusClassId={closedStatusClassId?.get()}
              closingStatusId={closedStatusIds.get() ?? undefined}
              onClosingStatusChange={(closingStatus) => {
                closedStatusIds.set(closingStatus)
                labelButton.set(translate('Search'))
              }}
            />
          </Grid>
          {systemConfig().displayConsummatedFilterInDashboardAndList.get() ===
            '1' ? (
            <Grid item xs={2} sx={{ pl: 1, pt: 1, width: '90%' }}>
              <SelectConsummatedAndAttempted
                disabled={lockFields}
                valueDefault={consummatedAndAttempted?.get() ?? null}
                onReportConsummatedAndAttempetdChange={(type) => {
                  consummatedAndAttempted?.set(type)
                }}
              />
            </Grid>
          ) : (
            false
          )}
          <Grid item xs={2} sx={{ pt: 1, pl: 1, pr: 0.2 }}>
            <FilterCheckboxQualifiers
              qualifiersSelected={qualifiersSelected}
              clearState={clearState?.get()}
            />
          </Grid>
          <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
            <FilterKeyword keyWords={comments} />
          </Grid>
          <Grid item xs={2} sx={{ pl: 2, pt: 1 }}>
            <FilterSwitch
              label={translate('Contain all keywords')}
              onCheckedChange={() => {
                includeAllWords?.set(!includeAllWords.value)
              }}
              disabled={lockFields}
              checked={includeAllWords?.get()}
              tooltip={'All words must exist in the comment'}
            />
          </Grid>
          <Grid item xs={4} sx={{ pl: 1, pt: 1 }}>
            <FilterSwitchOtherDispatchsGroup
              onCheckedChange={() => {
                labelButton.set(translate('Search'))
                withSolvedThirdParty.set(!withSolvedThirdParty.value)
              }}
              disabled={lockFields}
              checked={withSolvedThirdParty.get()}
            />
          </Grid>
          {verifyPermission(['occurrence_clerk_index']) && (
            <Grid
              item
              xs={4}
              sx={{ pt: 2, display: 'flex', alignItems: 'center' }}
            >
              <FilterSwitch
                label="Show only occurrences marked for clerk"
                onCheckedChange={() => {
                  labelButton?.set(translate('Search'))
                  onlyToClerk?.set(!onlyToClerk?.value)
                }}
                disabled={lockFields}
                checked={onlyToClerk.get()}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          direction={'row'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            pt: 2,
            gap: 2,
          }}
        >
          <Button variant="outlined"
            onClick={clearFilters}
          >
            {translate('Clear Filters')}
          </Button>
          <LoadingButton
            loading={loading.get()}
            variant="contained"
            onClick={searchButton}
            disabled={disabledSendButton()}
          >
            {labelButton.get()}
          </LoadingButton>

        </Grid>
      </Grid>
    </Collapse>

  )
}