import React from "react";
import polylabel from 'polylabel'
import L, { type LatLngExpression, type LatLngTuple } from 'leaflet'
import { Marker, useMapEvents } from 'react-leaflet'
import { useHookstate } from "@hookstate/core";


interface LabelAreasPlanProps {
  readonly positions: [];
  readonly label: string;
  readonly color: string;
}

export default function LabelAreasPlan({ positions, label, color }: LabelAreasPlanProps) {
  const showLabel = useHookstate(false)

  useMapEvents({
    zoomend: (event) => {
      if (event && event.target._zoom >= 10 && !showLabel.get()) {
        showLabel.set(true)
      } else if (showLabel.get() && event.target._zoom <= 10) {
        showLabel.set(false)
      }
    },
  });

  const center = polylabel([positions], 0.000001);
  const latLng: LatLngExpression = [center[0], center[1]] as LatLngTuple;

  const markerHtmlStyles = `
      color: ${color};
      white-space: nowrap;
      font-size: 16px;
      text-shadow: -1px 0 black, 0 1px black, 0.1px 0 black;
      font-weight: 400;
      width: 100px;
      height: 20px
      `

  const customIcon = L.divIcon({
    className: 'polygon-label',
    html: `<div style="${markerHtmlStyles}">${label}</div>`,
    iconSize: [0, 0],
  });
  console.log("center: ", center)

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {
        showLabel.get() &&
        <Marker position={latLng} icon={customIcon} interactive={false} />
      }
    </>
  )
}