/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import './styles.scss'
import { Message as MessageIcon } from '@mui/icons-material'
import ReactDOM from 'react-dom'
import StandardModal from '../../../../components/StandardModal'
import Message from '../Message'
import { useLang } from '../../../../hooks/useLang'
import { authFetch } from '~/services/fetch'
import { useHookstate } from '@hookstate/core'
import { useLocation } from 'react-router'
import ScreenSettingsDraggableProps from '~/features/Entry/interfaces/IPositionDraggable'
import useSystemState from '~/features/System/stores/SystemState'
import { storeScreenSettings } from '~/features/System/services'
import { Button } from '@mui/material'
import useMessageState from '../../stores/MessageState'

const modal = document.getElementById('div-modal') as HTMLElement

type MessageModalDragProps = {
  readonly closeEvent?: () => void
}

export default function MessageModalDraggable({
  closeEvent,
}: MessageModalDragProps) {
  const { translate } = useLang()
  const { windowManager, loadingButton } = useSystemState();
  const { newMessage } = useMessageState()
  const loadingComponent = useHookstate(true)
  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    loadingComponent.set(true)
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {

      if ('MessageModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.MessageModalDraggable)
      }
      loadingComponent.set(false)

    })

    return () => {
      windowManager()['MessageModal'].open.set(false)
      newMessage().receiver.id.set(null)
    }
  }, [])


  const handleStop = (event, dragElement) => {
    event.stopPropagation();
    event.preventDefault();

    getPositionsDraggable.x_position.set(dragElement.x);
    getPositionsDraggable.y_position.set(dragElement.y);
  };

  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'MessageModalDraggable',
        isOpen: windowManager()['MessageModal'].open.get(),
        isMinimized: getPositionsDraggable.is_window_minimized?.get(),
        positionX: getPositionsDraggable?.x_position?.get(),
        positionY: getPositionsDraggable?.y_position?.get(),
      })
    }
  }, [
    windowManager()['MessageModal'].open.get(),
    getPositionsDraggable.is_window_minimized?.get(),
    getPositionsDraggable?.x_position?.get(),
    getPositionsDraggable?.y_position?.get(),
  ])

  if (windowManager()['MessageModal'].open.get()) {
    return ReactDOM.createPortal(
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {!loadingButton.get() && !loadingComponent.get() &&
          <StandardModal
            title={translate('Messages')}
            avatar={MessageIcon}
            dimensions={{
              width: 800,
              height: 470
            }}
            closeButton={() => windowManager()['MessageModal'].open.set(false)}
            handleStop={handleStop}
            isExpanded={getPositionsDraggable.is_window_minimized}
            position={{
              x: getPositionsDraggable?.x_position?.get() ? Number(getPositionsDraggable?.x_position?.get()) : 250,
              y: getPositionsDraggable?.y_position?.get() ? Number(getPositionsDraggable?.y_position?.get()) : 100
            }}
          >
            <Message />
          </StandardModal>
        }
      </>
      , modal
    )
  } else {
    return <></>
  }
}
