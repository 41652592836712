import React, { useEffect } from 'react';
import { useLang } from '~/hooks/useLang';
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from '~/components/ErrorFallback';
import InternalHeader from '~/components/Layout/components/InternalHeader';
import InternalContent from '~/components/Layout/components/InternalContent';
import { useHookstate } from '@hookstate/core';
import Tab from '@mui/material/Tab';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { Grid, IconButton, Divider, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IListDispatchTab } from '~/features/Dispatch/components/TabListDispatch/interfaces/IListDispatchTab';
import DispatchTableUpdateSocket from '~/features/Dispatch/components/TabListDispatch/components/DispatchTableUpdateSocket';
import { getDispatchesByStatus } from '~/features/Dispatch/components/TabListDispatch/services';
import useDispatchTableState, { sortDispatchTable } from '~/features/Dispatch/components/TabListDispatch/stores/DispatchTableState';
import { useTheme } from '~/hooks/useTheme';
import RefreshIcon from '@mui/icons-material/Refresh';
import NotificationsCentral from '~/components/NotificationsCentral';
import DialogCloseMuiltipleDispatch from '~/components/ListDispatchesSocket/components/DialogCloseMultipleDispatch';
import IListDispatchSocket from '~/components/ListDispatchesSocket/interfaces/IListDispatchesProps';
import useDispatchState from '../../stores/DispatchState';
import { Close, FilterNoneOutlined, LibraryAddCheckOutlined } from '@mui/icons-material';
import useUserState from '~/features/User/stores/UserState';
import DispatchFilters from './components/DispatchFilters';


function toDomain(row) {
  return {
    id: row.id,
    occurrence_id: row.occurrence ? row.occurrence.id : null,
    code: row.code,
    status: row.status.acronym,
    statusId: row.status.id,
    dispatch_group: row.dispatch_group ? `${row.dispatch_group.name}` : '-',
    type: row.type ? `[${row.type.code}] ${row.type.name}` : '-',
    subtype: row.subtype ? `[${row.subtype.code}] ${row.subtype.name}` : '-',
    location: row.occurrence.location,
    city: row.occurrence.city ? row.occurrence.city.name : '-',
    district: row.occurrence.district ? row.occurrence.district.name : '',
    notes: row.hold ? row.hold?.notes : '-',
    priority: row.priority,
    elapsed_time: row.status_updated_at ? new Date(row.status_updated_at).toJSON() : row.dispatched_at,
    status_updated_at: row.status_updated_at ? new Date(row.status_updated_at).toJSON() : row.dispatched_at,
    highlight: row.occurrence.featured,
    dispatched_at: row.dispatched_at,
    call: row.occurrence.calls,
    recall_was_viewed: Boolean(row.occurrence.recall_was_viewed),
    closure_requested: row.occurrence.closure_requested,
  }
}

interface ITabListDispatch {
  readonly fullList?: boolean
}

export default function TabListDispatch({ fullList }: ITabListDispatch) {
  const { translate } = useLang();
  const { darkMode } = useTheme();
  const { verifyPermission } = useUserState();
  const { dispatchRowsTable, dispatchRowsTableFiltered } = useDispatchTableState();
  const { openModalResultMultipleCloseDispatches } = useDispatchState();
  const tab = useHookstate<IListDispatchTab>('pending');
  const badgeColor = !darkMode ? '#B1B2B3' : 'rgba(255, 255, 255, 0.64)'
  const loading = useHookstate(true);
  const open = useHookstate(false);
  const rowsSelected = useHookstate<readonly IListDispatchSocket[]>([])
  const enableCheckbox = useHookstate(false)
  const enableButtonShowResume = useHookstate(false)
  const searchCity = useHookstate('');


  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -8,
      top: -5,
      margin: '0 2px',
      backgroundColor: badgeColor
    },
  }));

  const clickOutModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      open.set(false);
    }
  };

  useEffect(() => {
    if (loading.get() === true) {
      Promise.all([
        getDispatchesByStatus({ targetUrl: '/dispatch/pending' }).then((response) => dispatchRowsTable()['pending'].set(sortDispatchTable(response.data.map((row) => toDomain(row))))),
        getDispatchesByStatus({ targetUrl: '/dispatch/dispatched' }).then((response) => dispatchRowsTable()['dispatched'].set(sortDispatchTable(response.data.map((row) => toDomain(row))))),
        getDispatchesByStatus({ targetUrl: '/dispatch/done', limit: 200 }).then((response) => dispatchRowsTable()['done'].set(sortDispatchTable(response.data.map((row) => toDomain(row))))),
        getDispatchesByStatus({ targetUrl: '/dispatch/on-hold' }).then((response) => dispatchRowsTable()['on-hold'].set(sortDispatchTable(response.data.map((row) => toDomain(row)))))
      ]).finally(() => loading.set(false))
    }
  }, [loading.get()])

  const handleTabDispatch = (event: React.SyntheticEvent, value: IListDispatchTab) => {
    tab.set(value);
  };



  return <ErrorBoundary FallbackComponent={ErrorFallback}>
    <InternalContent title={`${translate('Dispatches')}`} bold={true} actions={<Grid container justifyContent="flex-end">
      <IconButton title={translate('Update')} size="large" color="secondary" aria-label="add an alarm" disabled={loading.get()} onClick={() => loading.set(true)}>
        <RefreshIcon />
      </IconButton>
      <NotificationsCentral />

    </Grid>}>
      <Grid container>
        <Grid item xs={12} marginBottom={2}>
          <DispatchFilters
            searchCity={searchCity}
            clearFilters={() => {
              searchCity.set('')
            }}
          />
          <Divider />
        </Grid>
        <Grid item xs={12} marginBottom={2}>
          {(verifyPermission(['dispatch_close_multiple'])) &&
            tab.get() === 'pending' &&
            window.location.pathname === '/dispatch/dispatches/list-full' &&
            <>
              <IconButton
                onClick={() => enableCheckbox.set(!enableCheckbox.get())}
                title={enableCheckbox.get() ? translate('Disable Select') : translate('Enable Select')}
                size="large"
              >
                {enableCheckbox.get() ? <LibraryAddCheckOutlined fontSize='small' /> : <FilterNoneOutlined fontSize='small' />}
              </IconButton>

              <IconButton
                title={translate('Dispatch Close')}
                disabled={!rowsSelected.get().length || !enableCheckbox.get()}
                onClick={() => open.set(true)}
                size="large"
              >
                <Close fontSize='small' />
              </IconButton>

            </>
          }
          {
            enableButtonShowResume.get() &&
            fullList &&
            <Button
              variant='text'
              onClick={() => openModalResultMultipleCloseDispatches().set(true)}
              sx={{
                '&:hover': {
                  textDecoration: 'underline',
                  background: "none",
                },

              }}
            >
              {translate('Show Resume close Multiple Dispatch')}
            </Button>
          }
        </Grid>
        <Grid item xs={12} >
          <TabContext value={tab.get()}>
            <TabList onChange={handleTabDispatch}>
              <Tab
                sx={{ width: '150px' }}
                label={
                  <StyledBadge badgeContent={dispatchRowsTableFiltered().get()['pending'].length} color="info">
                    <b>
                      {translate('Pending')}
                    </b>
                  </StyledBadge>
                }
                value='pending' />
              <Tab
                sx={{ width: '150px' }}
                label={<StyledBadge badgeContent={dispatchRowsTableFiltered().get()['dispatched'].length} color="info">
                  <b>
                    {translate('Dispatched')}
                  </b>
                </StyledBadge>}
                value='dispatched' />
              <Tab
                sx={{ width: '150px' }}
                label={<StyledBadge badgeContent={dispatchRowsTableFiltered().get()['done'].length} color="info">
                  <b>
                    {translate('Finished')}
                  </b>
                </StyledBadge>}
                value='done' />
              <Tab
                sx={{ width: '150px' }}
                label={<StyledBadge badgeContent={dispatchRowsTableFiltered().get()['on-hold'].length} color="info">
                  <b>
                    {translate('Holded')}
                  </b>
                </StyledBadge>}
                value='on-hold' />
            </TabList>
            <TabPanel sx={{ padding: 0 }} value='pending'>
              <DispatchTableUpdateSocket typeStatus={'pending'} loadingRows={loading} fullList={fullList} enableCheckbox={enableCheckbox.get()} rowsSelected={rowsSelected} searchCity={searchCity} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value='dispatched'>
              <DispatchTableUpdateSocket typeStatus={'dispatched'} loadingRows={loading} fullList={fullList} searchCity={searchCity} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value='done'>
              <DispatchTableUpdateSocket typeStatus={'done'} loadingRows={loading} fullList={fullList} searchCity={searchCity} />
            </TabPanel>
            <TabPanel sx={{ padding: 0 }} value='on-hold'>
              <DispatchTableUpdateSocket typeStatus={'on-hold'} loadingRows={loading} fullList={fullList} searchCity={searchCity} />
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      <DialogCloseMuiltipleDispatch
        openDialog={open}
        onClose={clickOutModalClose}
        rowsSelected={rowsSelected}
        enableCheckBox={enableCheckbox}
        enableButtonShowResume={enableButtonShowResume}
      />
    </InternalContent>
  </ErrorBoundary>
}