/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react'
import { Grid, Typography } from "@mui/material";
import dayjs from 'dayjs';
import DetailsData from '~/features/Report/interfaces/DetailsData';
import { useLang } from '~/hooks/useLang';
import { stringToPhoneMask } from '~/utils/strings';
import { useHookstate } from '@hookstate/core';

export default function DetailedDispatchReport({ details }: {
  readonly details: DetailsData,
}) {
  const { translate } = useLang();
  const valuesDetails = useHookstate<string[]>([''])

  useEffect(() => {
    const keyValues = Object.keys(details.value)
    valuesDetails.set(keyValues)
  }, [])

  const printValue = (keyDetail, valueDetail) => {
    if (keyDetail == 'dispatched_at' || keyDetail == 'closed_at' || keyDetail == 'date_connected' || keyDetail == 'date_disconnected') {
      return (
        <Typography
          variant='subtitle2'><b>{translate(valueName[keyDetail] ? valueName[keyDetail] : keyDetail)}:</b>{valueDetail ? ` ${dayjs(valueDetail).format('DD/MM/YYYY HH:mm:ss')}` : `* ${translate('Uninformed')} *`}
        </Typography>
      )
    }

    if (keyDetail == 'total_time' || keyDetail == 'average_time') {
      return (
        <Typography
          variant='subtitle2'><b>{translate(valueName[keyDetail] ? valueName[keyDetail] : keyDetail)}:</b>{' '}{valueDetail ? valueDetail : `* ${translate('Uninformed')} *`}
        </Typography>
      )
    }

    if (keyDetail == 'phone') {
      return (
        <Typography
          variant='subtitle2'><b>{translate(valueName[keyDetail] ? valueName[keyDetail] : keyDetail)}:</b>{valueDetail ? ` ${stringToPhoneMask(valueDetail)}` : `* ${translate('Uninformed')} *`}
        </Typography>
      )
    }

    return (
      <Typography
        variant='subtitle2'><b>{translate(valueName[keyDetail] ? valueName[keyDetail] : keyDetail)}:</b>{valueDetail ? ` ${valueDetail}` : `* ${translate('Uninformed')} *`}
      </Typography>
    )
  }

  const valueName = {
    'entry_origin': 'Entry Origin',
    'phone': 'Phone',
    'requester': 'Requester',
    'agency': 'Agency:name',
    'dispatch_group': 'Dispatch Group',
    'city': 'City',
    'connected_by': 'Connected by',
    'address_reference': 'Address Reference',
  }


  return (
    <Grid container>
      <Grid container paddingY={1} marginLeft={1.5}>
        <Grid item>
          <>
            {
              valuesDetails.get().map(keyDetail => {
                return printValue(keyDetail, details.value[keyDetail])
              }
              )
            }
          </>
        </Grid>
      </Grid>
    </Grid>
  )
}