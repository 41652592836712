import { useHookstate } from '@hookstate/core';
import React, { useEffect } from 'react';
import CrudIndex from '~/components/CrudIndex';
import Layout from '~/components/Layout';
import { useLang } from '~/hooks/useLang';

export default function Index() {
  const { translate } = useLang();

  const forceRefresh = useHookstate<boolean>(false);
  const lockFields = useHookstate<boolean>(false);
  const loading = useHookstate<boolean>(false);
  const agencyId = useHookstate<number | null>(null);
  const dispatchGroupName = useHookstate<string | null>(null);

  useEffect(() => {
    document.title = translate('Administration - Dispatch System')
  }, [])

  const dispatchGroupFilter = [
    {
      type: 'name',
      placeholder: 'Name',
      variable: dispatchGroupName
    },
    {
      type: 'agency_id',
      variable: agencyId
    },
  ]
  return (
    <Layout marginLayout={true}>
      <CrudIndex
        model='dispatch/group'
        title='Dispatch Group'
        exceptColumns={['agency_id', 'dispatch_group_id']}
        forceOrderColumns={['name', 'acronym', 'agency:name', 'is_non_operational']}
        booleanField={['is_non_operational']}
        aliasLabel={[{column: 'is_non_operational', label: 'is operational'}]}
        loading={loading}
        lockFields={lockFields.get()}
        clearFilters={() => {
          dispatchGroupName.set('')
          agencyId.set(null)
          forceRefresh.set(!forceRefresh.get())
        }}
        filters={dispatchGroupFilter}
        searchButton={() => forceRefresh.set(!forceRefresh.get())}
        forceRefresh={forceRefresh.get()}
        disableColumnsFilters={true}
        canView={true}
        showInViewMore={['name', 'acronym', 'agency:name', 'areas', 'created_at']}
        viewTitle='Dispatch Group'
      />
    </Layout>
  )
}
