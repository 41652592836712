import React from 'react';
import { useForm } from 'react-hook-form';
import CrudEdit from '~/components/CrudEdit';
import IDispatchGroup from '../interfaces/IDispatchGroup';
import { useParams } from 'react-router-dom';
import ICrudEditParams from '~/components/CrudEdit/interfaces/ICrudEditParams';

export default function Edit() {     
  const {register, handleSubmit, control, setValue:setFormValue,getValues} = useForm<IDispatchGroup>();
    const fields = [
      {name: 'id', type: 'hidden'},
      { name: 'name', type: 'string', required: true },
      { name: 'acronym', type: 'string', required: true },
      { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder: 'Agency:name', required: true },
      { name: 'is_non_operational', type: 'switch', checked: false, placeholder: 'Is non operacional' },
      { name: 'area_ids', type: 'transferList', options: {firstListUrl: `/dispatch/group/${useParams<ICrudEditParams>().id}/get-areas`, secondListUrl: '/area'} , model: 'area', placeholder: 'Associated Areas' },
  ];
  
    return(
      <CrudEdit 
        model={'dispatch/group'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        setFormValue={setFormValue}
        messageSuccess={'Dispatch Group successfully updated!'}  
        title={'Edit Dispatch Group'} 
        getValues={getValues}
      />
    )
}



