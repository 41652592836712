import { authFetch } from "~/services/fetch";

export async function makeRequestToGetDevicesByDispatchGroupAndAgency({
  agencyId,
  dispatchGroupId,
  exportCSV = false,
  groupSetId
}) {
  try {
    const response = await authFetch({
      url: `/report/devices`,
      method: 'POST',
      data: {
        agency_ids: agencyId,
        dispatch_group_ids: dispatchGroupId,
        export_csv: exportCSV,
        group_set_id: groupSetId
      }
    });

    return response.data
  } catch (error) {
    console.error('ERROR GENERATE REPORT', error)
    throw error
  }
}