import { authFetch } from "~/services/fetch";

export async function makeRequestToGetReportServiceDevices({
  startDate,
  finishDate,
  dispatchGroupsId,
  agencyIds,
  withSolvedThirdParty,
  exportCSV = false,
  groupSetId
}) {
  try {
    const response = await authFetch({
      url: '/report/device-services',
      method: 'POST',
      data: {
        start_date: startDate,
        finish_date: finishDate,
        dispatch_group_ids: dispatchGroupsId,
        agency_ids: agencyIds,
        with_solved_third_party: withSolvedThirdParty,
        export_csv: exportCSV,
        group_set_id: groupSetId
      }
    });

    return response.data;

  } catch (error) {
    console.error('ERROR GENERATE REPORT', error)
    throw error
  }
}