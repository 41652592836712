import React from 'react'
import { Downgraded, State, createState, none, useHookstate, useState } from '@hookstate/core'
import { Validation as validation } from '@hookstate/validation'
import { Broadcasted as broadcasted } from '@hookstate/broadcasted'
import IDispatch from '../interfaces/IDispatch'
import IDispatchStatusActions from '../interfaces/IDispatchStatusActions'
import IDispatchState from '../interfaces/IDispatchState'
import useDispatchCommentState from './DispatchCommentState'
import useDispatchVehicleState from './DispatchVehicleState'
import useDispatchRelatedState from './DispatchRelatedState'
import { LatLng } from 'leaflet'
import { authFetch } from '~/services/fetch'
import { formatForSending, parseDataDispatch } from '../utils/parsers'
import { validPhone } from '~/utils/validators'
import { useLang } from '~/hooks/useLang'
import axios, { AxiosRequestConfig } from 'axios'
import notify from '~/utils/notify'
import IUnifyDispatch from '../interfaces/IUnifyDispatch'
import { getDispatchByIdService, saveLogPrintDispatch } from '~/features/Dispatch/services/index';
import { useMarkerState } from '~/features/Map/stores/MapState';

type CopyDispatchProps = {
  readonly loading: State<boolean>
  readonly dispatchGroupsIds: []
  readonly stateModal?: State<boolean>
}

type CloseDispatchProps = {
  readonly closedStatusId: number | string
  readonly comment: string
  readonly operation: string | null
  readonly isConsummated: boolean | null
}

export const dispatchDefault: IDispatch = {
  id: null,
  code: null,
  inRouteAt: null,
  inPlaceAt: null,
  dispatchedAt: null,
  closedAt: null,
  priority: 0,
  agency: {
    id: null,
    name: null,
    acronym: null,
  },
  closedStatus: {
    id: null,
    name: null,
    acronym: null,
  },
  dispatchGroup: {
    id: null,
    name: null,
    acronym: null,
  },
  type: {
    id: null,
    name: null,
    code: null,
    mustInformConsummate: false,
  },
  subtype: {
    id: null,
    name: null,
    code: null,
  },
  status: {
    id: null,
    name: null,
    acronym: null,
  },
  occurrence: {
    id: null,
    location: null,
    name: null,
    phone: null,
    address: null,
    latitude: null,
    longitude: null,
    entryAt: null,
    createdAt: null,
    calls: 1,
    toClerk: null,
    operation: {
      id: null,
      name: null,
    },
    state: {
      id: null,
      name: null,
      uf: null,
    },
    city: {
      id: null,
      name: null,
    },
    district: {
      id: null,
      name: null,
    },
    entry_origin: {
      id: null,
      name: null,
    },
    entry_type: {
      id: null,
      name: null,
      code: null
    },
    entry_subtype: {
      id: null,
      name: null,
      code: null
    },

    reference: null,
    featured: false,
  },
  hold: {
    id: null,
    occurrence_id: null,
    dispatch_id: null,
    was_communicated: null,
    holding_until_at: null,
    returned_at: null,
    created_user_id: null,
    returned_user_id: null,
    occurrence_comment_id: null,
    notes: null,
    device_id: null,
    created_station_id: null,
    returned_station_id: null,
    created_at: null,
    updated_at: null,
    deleted_at: null,
  },
  dispatchRetainHours: 0,
  dispatchRetainMinutes: 0,
  hasUnified: true,
  totalSubtypes: 0,
  totalDistricts: 0
}

const dispatchStatusActionsDefault: IDispatchStatusActions = {
  canCopy: true,
  canPrint: true,
  canRegistration: true,
  canReport: true,
  canClose: true,
  canCreate: true,
  canSave: false,
  canLocation: false,
  canMedia: false,
  canDispatchName: false,
  canAddVehicles: true,
  hasPrev: false,
  hasNext: false,
  canChronology: true,
  canHighlight: true,
  canReopen: true,
  canHold: true,
  canUnify: true,
  canDisunify: true,
  editingDispatch: false,
  editingDispatchRequester: false,
  isOpenUnification: false,
  isOpenRegistration: false,
  isOpenReport: false,
  isOpenDisunification: false,
  canClosePopup: false,
}

type SyncDispatchFromPayloadParams = {
  readonly dispatchParams: {
    readonly dispatch: IDispatch
    readonly hasNext?: boolean
    readonly hasPrev?: boolean
    readonly hasUnified?: boolean
  }
  readonly canSaveDispatch: boolean
  readonly canCreateDispatch: boolean
}

const dispatchStore = Object.create(dispatchDefault);

const store = createState<IDispatchState>({
  dispatch: dispatchStore,
  dispatchStatusActions: dispatchStatusActionsDefault,
  openModalResultMultipleCloseDispatches: false,
  loadingDispatchForm: false
})

store.attach(broadcasted('dispatch-events'))

// eslint-disable-next-line max-lines-per-function
export default function useDispatchState() {
  const dispatch = useState(store.dispatch);
  const {
    id,
    type,
    subtype,
    status,
    occurrence: {
      name,
      phone,
      id: occurrenceId,
      reference,
      location,
      city,
      district,
      latitude,
      longitude,
      calls,
      toClerk,
    },
    totalSubtypes,
    totalDistricts
  } = dispatch;
  const { translate } = useLang();
  const openModalResultMultipleCloseDispatches = useState(store.openModalResultMultipleCloseDispatches)
  const loadingDispatchForm = useState(store.loadingDispatchForm)
  const dispatchStatusActions = useState(store.dispatchStatusActions);
  const { canCreate, canSave, hasPrev, hasNext, editingDispatch, editingDispatchRequester, canClosePopup } = dispatchStatusActions;
  const { syncPreviousComments, setDefaultComments } = useDispatchCommentState();
  const { syncVehicleListInDispatch, syncVehicleList, setDefaultvehicleListInDispatch } = useDispatchVehicleState();
  const { syncRelatedDispatches, dispatchRelated, setDefaultdispatchRelated } = useDispatchRelatedState();
  const { createMarker } = useMarkerState();

  id.attach(validation)
  validation(id).validate(
    () => Boolean(id.get() != null),
    'Se o ID está valido'
  )

  if (phone) {
    phone.attach(validation)
    validation(phone).validate(
      () => validPhone(phone.get()),
      'Telefone Completo'
    )
  }

  if (type.id) {
    type.id.attach(validation);
    validation(type.id).validate(() => Boolean(type.id.get() != null), 'Se o Tipo está valido');
  }

  if (subtype.id) {
    subtype.id.attach(validation);
    validation(subtype.id).validate(() => (totalSubtypes.get() > 0 ? totalSubtypes.get() > 0 && subtype.id.get() != null : true), 'Se precisa informar um subtipo');
  }

  if (district?.id) {
    district.id.attach(validation);
    validation(district.id).validate(() => (totalDistricts.get() > 0 ? totalDistricts.get() > 0 && district.id.get() != null : true), 'Se precisa informar um subtipo');
  }



  const syncStates = (id) => {
    setDefaultComments()
    setDefaultdispatchRelated()
    setDefaultvehicleListInDispatch()
    return Promise.all([
      syncPreviousComments(id),
      syncRelatedDispatches(id),
      syncVehicleListInDispatch(id),
    ])
  }
  const syncVehicleDispatchState = (id: number) => {
    return Promise.all([
      syncVehicleListInDispatch(id),
    ])
  }

  const setDefaultDispatch = () => {
    dispatch.merge({
      id: null,
      code: null,
      inRouteAt: null,
      inPlaceAt: null,
      dispatchedAt: null,
      closedAt: null,
      priority: 0,
      agency: {
        id: null,
        name: null,
        acronym: null,
      },
      closedStatus: {
        id: null,
        name: null,
        acronym: null,
      },
      dispatchGroup: {
        id: null,
        name: null,
        acronym: null,
      },
      type: {
        id: null,
        name: null,
        code: null,
        mustInformConsummate: false,
      },
      subtype: {
        id: null,
        name: null,
        code: null,
      },
      status: {
        id: null,
        name: null,
        acronym: null,
      },
      occurrence: {
        id: null,
        location: null,
        name: null,
        phone: null,
        address: null,
        latitude: null,
        longitude: null,
        entryAt: null,
        createdAt: null,
        calls: 1,
        toClerk: null,
        operation: {
          id: null,
          name: null,
        },
        state: {
          id: null,
          name: null,
          uf: null,
        },
        city: {
          id: null,
          name: null,
        },
        district: {
          id: null,
          name: null,
        },
        entry_origin: {
          id: null,
          name: null,
        },
        entry_type: {
          id: null,
          name: null,
          code: null
        },
        entry_subtype: {
          id: null,
          name: null,
          code: null
        },

        reference: null,
        featured: false,
      },
      hold: {
        id: null,
        occurrence_id: null,
        dispatch_id: null,
        was_communicated: null,
        holding_until_at: null,
        returned_at: null,
        created_user_id: null,
        returned_user_id: null,
        occurrence_comment_id: null,
        notes: null,
        device_id: null,
        created_station_id: null,
        returned_station_id: null,
        created_at: null,
        updated_at: null,
        deleted_at: null,
      },
      dispatchRetainHours: 0,
      dispatchRetainMinutes: 0,
      hasUnified: true,
      totalSubtypes: 0
    })
  }


  async function syncDispatchFromId(dispatchId: number | null | undefined): Promise<{ readonly ok: boolean, readonly dispatch: IDispatch }> {
    setDefaultDispatch()
    return new Promise((resolve, reject) => {
      getDispatchByIdService(dispatchId)
        .then((dispatchData) => {
          const dispatch: any | null = dispatchData ?? null;

          if (!dispatch) return reject(new Error('Without a Dispatch in response'))

          syncDispatchFromPayload({
            dispatchParams: {
              dispatch: parseDataDispatch(dispatch),
              hasNext: false
            },
            canCreateDispatch: true,
            canSaveDispatch: true,
          })
            .then(() => resolve({ ok: true, dispatch }));
        })
        .catch((err) => reject(err))
    })
  }

  function syncDispatchFromPayload({
    dispatchParams: {
      dispatch: dispatchObj,
      hasPrev: hasPrevDispatch = true,
      hasNext: hasNextDispatch = false,
      hasUnified: hasUnifiedDispatch = false
    },
    canCreateDispatch = false,
    canSaveDispatch = true,
  }: SyncDispatchFromPayloadParams) {
    // setDefaultDispatch()
    dispatch.set(dispatchObj)
    canCreate.set(canCreateDispatch)
    canSave.set(canSaveDispatch)
    hasPrev.set(hasPrevDispatch)
    hasNext.set(hasNextDispatch)
    editingDispatch.set(false);
    editingDispatchRequester.set(false);
    return syncStates(dispatchObj['id']);
  }

  function getRequestUrlForDispatchPosition(
    type: 'previous' | 'next' | 'last'
  ) {
    const suburl =
      type == 'previous'
        ? 'get-my-previous'
        : type == 'next'
          ? 'get-my-next'
          : 'get-my-last'
    return `/dispatch/${suburl}`
  }

  async function getMyDispatch(
    type: 'previous' | 'next' | 'last',
    currentDispatch: string | number | null = null
  ) {
    loadingDispatchForm.set(true)
    setDefaultDispatch()
    if (type !== 'last' && !currentDispatch)
      throw Error(
        'Previous and next must specify an id on which it can based on'
      )
    const requestUrl = getRequestUrlForDispatchPosition(type)
    const axiosRequest: AxiosRequestConfig<any> = {
      url: requestUrl,
      method: 'POST',
    }
    if (currentDispatch) axiosRequest['data'] = { idRef: currentDispatch }

    return await authFetch(axiosRequest).then((response) => {
      return syncDispatchFromPayload({
        dispatchParams: {
          dispatch: parseDataDispatch(response.data.dispatch),
          hasNext: response.data.hasNext,
          hasPrev: response.data.hasPrev,
          hasUnified: response.data.hasUnified,
        },
        canCreateDispatch: true,
        canSaveDispatch: true,
      })
    }).finally(() => {
      loadingDispatchForm.set(false)
    })
  }

  return {
    dispatch: () => dispatch,
    dispatchStatusActions: () => dispatchStatusActions,
    openModalResultMultipleCloseDispatches: () => openModalResultMultipleCloseDispatches,
    loadingDispatchForm: () => loadingDispatchForm,
    getMyLastDispatch: () => getMyDispatch('last'),
    previousDispatch: () => getMyDispatch('previous', id.get()),
    syncDispatchFromId: syncDispatchFromId,
    setDefaultDispatch: setDefaultDispatch,
    syncDispatchFromPayload: syncDispatchFromPayload,
    nextDispatch: () => getMyDispatch('next', id.get()),
    saveDispatch: async () => {
      authFetch({
        url: `/dispatch/${id.get()}`,
        method: 'PUT',
        data: formatForSending(dispatch.get()),
      })
    },
    copyDispatch: async ({ dispatchGroupsIds, loading, stateModal }: CopyDispatchProps) => {
      loading.set(true)
      authFetch({
        url: '/dispatch/copy',
        method: 'POST',
        data: {
          dispatch_id: id.get(),
          dispatch_group_ids: dispatchGroupsIds,
        },
      }).then(({ data }) => {
        if (data) {
          data.map((dispatch) => {
            dispatchRelated().merge({
              [dispatch.id]: { id: dispatch.id, code: dispatch.code, status: dispatch.status },
            })
          })
          notify({
            message: `${translate('Dispatch copied successfully!', {
              code: data.code,
            })}`,
            type: 'success',
          })
        }
      }).finally(() => {
        loading.set(false)
        stateModal?.set(false)
      })
    },
    closeDispatch: async (params: CloseDispatchProps) => {
      const data = authFetch({
        url: '/dispatch/close',
        method: 'POST',
        data: {
          dispatch_id: id.get(),
          closed_status_id: params.closedStatusId,
          operation_id: params.operation,
          comment: params.comment,
          is_consummate: params.isConsummated
        },
      }).then((response) => {
        if (response.status === 200) {
          getMyDispatch('last').then(() => {
            notify({
              message: `${translate('Finished successfully!')}`,
              type: 'success',
            })
          })
        }
      })

      return data
    },
    associateVehicleDispatch: async (deviceId: number | string, loading?: State<boolean>) => {
      const dispatchId = id.get();
      loading?.set(true)
      if (!deviceId) return console.error('DeviceId Nulo');
      if (!dispatchId) return console.error('Dispatch Nulo');
      authFetch({
        url: '/dispatch/associate',
        method: 'POST',
        data: {
          dispatch_id: id.get(),
          device_id: deviceId,
        },
      }).then(async () => {
        loading?.set(false)
        syncVehicleDispatchState(dispatchId).then(
          () => notify({
            message: translate('Added successfully'),
            type: 'success',
          }));
        return true
      }).catch((error) => {
        console.error(error)
      })

      return true
    },
    syncVehicleDispatchState,
    reopenDispatch: async (loading?: State<boolean>) => {
      loading?.set(true)
      authFetch({
        url: '/dispatch/reopen',
        method: 'POST',
        data: {
          dispatch_id: id.get(),
        },
      }).then(() => {
        syncDispatchFromId(id.get()).then(() => {
          notify({
            message: `${translate('Reopened successfully!')}`,
            type: 'success',
          })
        })
      }).finally(() => {
        loading?.set(false)
      })
    },
    holdDispatch: async (minutes, comment, notifyDispatchHolded, loading?: State<boolean>) => {
      loading?.set(true)
      authFetch({
        url: '/dispatch/hold-dispatch',
        method: 'POST',
        data: {
          dispatch_id: id.get(),
          holding_until: minutes,
          notes: comment,
          notify: notifyDispatchHolded
        },
      }).then(() => {
        syncDispatchFromId(id.get()).then(() => {
          notify({
            message: `${translate('Hold successfully!')}`,
            type: 'success',
          })
        })
      }).finally(() => {
        loading?.set(false)
      })
    },
    freeDispatch: async () => {
      authFetch({
        url: '/dispatch/free-dispatch',
        method: 'POST',
        data: {
          dispatch_id: id.get(),
        },
      }).then(() => {
        syncDispatchFromId(id.get()).then(() => {
          notify({
            message: `${translate('Free successfully!')}`,
            type: 'success',
          })
        })
      })
    },
    getDispatchByCode: (code: string, notOnlyVisibles = false) => {
      const cancelToken = axios.CancelToken.source()

      // change any-type just testing !
      const dispatchByCodePromise = new Promise<readonly IDispatch[]>(
        (resolve, reject) => {
          authFetch({
            url: '/dispatch/find',
            data: {
              code,
              not_only_visibles: notOnlyVisibles
            },
            method: 'POST',
            cancelToken: cancelToken.token,
          })
            .then(({ data, status }) => {
              if (!data.length)
                reject(
                  new Error(translate('Not Found Dispatch with this code'))
                )
              resolve(data)
            })
            .catch((err) => reject(err))
        }
      )

      return { promise: dispatchByCodePromise, cancelToken }
    },
    getDispatchById: getDispatchByIdService,
    updateDispatch: async () => {
      if (validation(type.id).valid() && validation(subtype.id).valid() && validation(district.id).valid()) {
        const responseAddress = await authFetch({
          url: '/dispatch/address/change',
          method: 'POST',
          data: {
            dispatch_id: id.get(),
            address_reference: reference.get(),
            location: location.get(),
            city_id: city.id.get(),
            district_id: district.id.get(),
            latitude: latitude.get(),
            longitude: longitude.get(),
          },
        })

        const responseType = await authFetch({
          url: '/dispatch/' + id.get(),
          method: 'PUT',
          data: {
            type_id: type.id.get(),
            subtype_id: subtype.id.get(),
          },
        })
        notify({
          message: `${translate('Saved successfully!')}`,
          type: 'success',
        })
        syncDispatchFromId(id.get()).then(() => {
          responseType.data?.newDispatches?.length > 0 ?
            notify({
              message: `Novos grupos adicionados ${responseType.data.newDispatches.map(element => element.code)}`,
              type: 'success',
            })
            : false
        })
        editingDispatch.set(false)
      } else {
        notify({ message: `${translate('Please check the form information and try again!')}`, type: 'error' });
      }
    },
    updateDispatchRequester: async () => {
      const responseRequester = await authFetch({
        url: '/dispatch/change-requester',
        method: 'POST',
        data: {
          dispatch_id: dispatch.id.get(),
          name: name.get(),
          phone: phone.get()
        }
      })
      notify({
        message: `${translate('Saved successfully!')}`,
        type: 'success',
      })
      editingDispatchRequester.set(false)
    },
    unificationDispatch: async (param: IUnifyDispatch) => {
      authFetch({
        url: '/dispatch/unify',
        method: 'POST',
        data: {
          dispatch_origin_id: id.get(),
          dispatch_destination_id: param.dispatchId,
        },
      }).then((response) => {
        syncDispatchFromId(response.data.id).then(() => {
          notify({
            message: `${translate('Dispatch unified successfully!')}`,
            type: 'success',
          })
        })
      })
    },
    disunificationDispatch: async ({ unificationId, dispatchUnifiedId }) => {
      authFetch({
        url: '/dispatch/disunify',
        method: 'POST',
        data: {
          dispatch_unified_id: unificationId,
        },
      }).then(() => {
        syncDispatchFromId(dispatchUnifiedId).then(() => {
          notify({
            message: `${translate('Dispatch disunified successfully!')}`,
            type: 'success',
          })
        })
      })
    },
    getTimelineItems: () => {
      if (occurrenceId.get() != null) {
        authFetch({
          url: `/occurrence/${occurrenceId.get()}/timeline`,
          method: 'POST',
        }).then((data) => {
          return data.data
        })
      } else {
        return []
      }
    },
    socketUpdateLocation: ({ dispatchId, geolocation }) => {
      if (dispatchId == id.get()) {
        location.set(geolocation.location);
        latitude.set(geolocation.latitude);
        longitude.set(geolocation.longitude);
      }
    },
    socketUpdateCallsDispatch: ({ callsNumber, dispatchId }) => {
      if (dispatchId == id.get()) {
        calls.set(callsNumber)
      }
    },
    printDispatch: (dispatchId: string | number | null) => {
      if (!dispatchId) return null;
      window.open(`/dispatch/print/${dispatchId}`)
    }
  }
}
