/* eslint-disable max-lines-per-function */
import { State, useHookstate } from '@hookstate/core';
import { Box, CardContent, CardActions, Dialog, TextField, TextFieldProps, Button, FormControl, Typography, CircularProgress, DialogTitle, Autocomplete, DialogContentText, DialogContent, DialogActions } from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { useLang } from '~/hooks/useLang';
import notify from '~/utils/notify';
import { cancelTransferDevice, checkTransferDevice, trasnferDeviceForDispatchGroup } from '../../services';
import { LoadingButton } from '@mui/lab';
import userUserState from '~/features/User/stores/UserState';
import FilterAutocompleteDispatchGroup from '~/components/FilterAutocompleteDispatchGroup';
import * as zod from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import type { DispatchGroupResponse } from '~/components/FilterAutocompleteDispatchGroup/interface';
import { getDispatchGroup } from '~/components/FilterAutocompleteDispatchGroup/service';
import { Height } from '@mui/icons-material';


export interface DeviceTransferError {
  readonly model: string | readonly string[] | null,
  readonly message: string | null
}
interface Field {
  readonly name: string,
  readonly model: string,
  readonly type?: 'textField' | 'select' | 'date',
  readonly required?: boolean,
  readonly defaultValue?: string | null
}

interface DeviceTransferDialogProps {
  readonly title: string,
  readonly fields: ReadonlyArray<Field>,
  readonly deviceId: number | null,
  readonly deviceName: string | null,
  readonly isOpen: State<boolean>
  readonly agencyId?: number | null,
  readonly dispatchGroup?: string | null,
  readonly dispatchGroupId?: number | null,
  readonly handleLoadAllVehicles?: () => void
}

const transferDeviceSchema = zod.object({
  dispatchGroupId: zod.number({ message: 'Select a dispatch group' }),
  destinyName: zod.string().nullable(),
  dateTobeReturned: zod.coerce.date().nullable().default(null)
})

type transferDeviceFormData = zod.infer<typeof transferDeviceSchema>

export function DeviceTransferDialog({
  fields,
  title,
  deviceId,
  deviceName,
  isOpen,
  agencyId,
  dispatchGroup,
  dispatchGroupId,
  handleLoadAllVehicles,
}: DeviceTransferDialogProps) {
  const { translate } = useLang();
  const { verifyPermission } = userUserState();

  const error = useHookstate<DeviceTransferError>({ model: null, message: null });
  const canTransferDevice = useHookstate(false);
  const isLoading = useHookstate(false);
  const isSending = useHookstate(false);
  const destinyNameState = useHookstate<string | null>(null);
  const openState = useHookstate(isOpen);
  const isShowCancelScheduleButton = useHookstate(false);
  const errorCheckTransfer = useHookstate('');
  const [optionsFilter, setOptionsFilter] = useState<readonly DispatchGroupResponse[]>([]);
  const textFieldsRef = useRef<Record<string, TextFieldProps>>({});
  const dispatchGroupIdSelected = useHookstate<number | null>(null)

  const { register, resetField, handleSubmit, control, watch, setValue, setError, clearErrors, formState: { errors } } = useForm<transferDeviceFormData>({
    resolver: zodResolver(transferDeviceSchema),
    criteriaMode: "all",
    defaultValues: {
      dateTobeReturned: null,
      destinyName: null,
    },
  })

  console.log("oke: ", dispatchGroupId)

  async function onSubmitForm(data: transferDeviceFormData) {
    isSending.set(true)

    if (!data.dispatchGroupId) {

      setError("dispatchGroupId", {
        types: {
          required: "This is required",
          message: "Campo obrigatório"
        }
      })

      isSending.set(false)

      return false;
    }

    if (!deviceId) {
      error.message.set(translate('No Device Id'));
      isSending.set(false)
      return false;
    }

    trasnferDeviceForDispatchGroup({
      deviceId: deviceId,
      destDispatchGroupId: data.dispatchGroupId,
      destinyName: data.destinyName,
      dateTobeReturned: dayjs(data.dateTobeReturned)
    }).then((response) => {
      openState.set(false);

      if (response.status === 200) {
        notify({
          message: translate('Device was transferred successfully!'),
          type: 'success'
        });
        resetField('dispatchGroupId')
        resetField('dateTobeReturned')
        resetField('destinyName')


      }
    }).finally(() => {
      openState.set(false)
      isSending.set(false)
      isLoading.set(false)
    })
  }

  useEffect(() => {
    resetField('dateTobeReturned')
    clearErrors('dispatchGroupId')

    if (deviceId && isOpen.get()) {
      checkDeviceIsAlreadyToTransfer();

      getDispatchGroup().then(dispatchGroup => {
        setOptionsFilter(dispatchGroup)
      })
    }

  }, [deviceId, isOpen.get()]);

  async function onCancelScheduleTransfer() {
    isSending.set(true);

    cancelTransferDevice({ deviceId: deviceId }).then((response) => {
      if (response.status === 200) {
        notify({
          message: translate('Schedule to device was canceled successfully!'),
          type: 'success'
        });
      }
    }).finally(() => {
      openState.set(false)
      isSending.set(false)
      isLoading.set(false)
    });
  };

  const checkDeviceIsAlreadyToTransfer = React.useCallback(async () => {
    isLoading.set(true);
    errorCheckTransfer.set('');

    checkTransferDevice({ deviceId: deviceId }).then((response) => {
      if (response.status === 200) {
        const checkDevice = response.data as { readonly authorized: boolean; readonly error: string; readonly scheduled: boolean; };
        canTransferDevice.set(checkDevice.authorized === true);
        isShowCancelScheduleButton.set(checkDevice.scheduled === true);

        if (checkDevice.error) {
          errorCheckTransfer.set(checkDevice.error);
        }
      }

    }).catch((err) => {
      errorCheckTransfer.set(err);
    }).finally(() => {
      setTimeout(() => {
        isLoading.set(false)
      }, 500)
    });
  }, [deviceId]);


  useEffect(() => {
    if (canTransferDevice.get()) {
      if ('destiny_name' in textFieldsRef.current) {
        const item = textFieldsRef.current['destiny_name'];

        if (item) {
          // eslint-disable-next-line functional/immutable-data
          if (deviceName) {
            // eslint-disable-next-line functional/immutable-data
            item['value'] = deviceName;
          }
        }
      }
    }
  }, [canTransferDevice])

  const clickOutModalClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      openState.set(false)
      isSending.set(false)
      isLoading.set(false)
    }
  };

  return (
    <Dialog
      open={openState.get()}
      onClose={clickOutModalClose}
      maxWidth='xs'
      fullWidth
    >
      <DialogTitle>
        {`${title} ${(destinyNameState.get() ? destinyNameState.get() : deviceName) ?? ""}`}
      </DialogTitle>
      <form onSubmit={handleSubmit(onSubmitForm)} >
        <DialogContent sx={{ padding: '0px 24px' }}>
          {(!isLoading.get() && errorCheckTransfer.get()) && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Typography>
                {translate(errorCheckTransfer.value)}
              </Typography>
            </Box>
          )}
          {canTransferDevice.get() && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {!isLoading.get() ?
                fields.map((field) => {
                  if (field.type === 'select') {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <FormControl fullWidth size='small'>
                        <Controller
                          {...register('dispatchGroupId')}
                          name={'dispatchGroupId'}
                          control={control}
                          defaultValue={dispatchGroupId ? dispatchGroupId : undefined}
                          render={(props) => (
                            <Autocomplete
                              {...props}
                              id='filter-dispatch-group'
                              limitTags={2}
                              options={optionsFilter.filter((value) => value.name !== dispatchGroup)}
                              value={props.field.value && optionsFilter ? optionsFilter.find(element => element?.id === props.field.value) : null}
                              noOptionsText={translate('No options')}
                              clearText={translate('Clear')}
                              size='small'
                              loading={isLoading.get()}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              getOptionLabel={(option) => option.name ?? ''}
                              onChange={(_, type) => {
                                props.field.onChange(type?.id);
                                dispatchGroupIdSelected.set(type?.id ? type.id : null)
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={translate('Dispatchs Groups')}
                                  error={!!errors.dispatchGroupId?.message}
                                  margin={'dense'}
                                />
                              )} />
                          )} />
                      </FormControl>
                    )
                  }
                  if (field.type === 'date') {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={'pt-br'}
                      >
                        <Controller
                          {...register('dateTobeReturned')}
                          control={control}
                          name={'dateTobeReturned'}
                          render={(props) => {
                            return <DateTimePicker
                              label={field.name}
                              format="DD/MM/YYYY HH:mm"
                              ampm={false}
                              ampmInClock={false}
                              defaultValue={dayjs(new Date())}
                              sx={{ margin: '6px 0px' }}
                              value={props.field.value ? dayjs(props.field.value) : null}
                              onChange={(newValue: Dayjs | null) => {
                                props.field.onChange(newValue);
                                return newValue;
                              }}
                              slotProps={{
                                textField: {
                                  fullWidth: true,
                                  size: 'small'
                                }
                              }}
                            />;
                          }}
                        />
                      </LocalizationProvider>
                    )
                  }
                  return (
                    // eslint-disable-next-line react/jsx-key
                    <Controller
                      {...register('destinyName')}
                      name={'destinyName'}
                      control={control}
                      render={({
                        field: { onChange } }) => {
                        return (
                          <TextField
                            size={'small'}
                            defaultValue={deviceName}
                            key={field.name}
                            label={field.name}
                            required={field.required}
                            fullWidth
                            onChange={({ target: { value } }) => {
                              onChange(value)
                              if (setValue) setValue('destinyName', value)
                            }}
                            margin='dense'
                          />
                        )
                      }}
                    />
                  )
                })
                :
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={30} />
                </Box>
              }
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            padding: '16px 24px '
          }}
        >
          <Button
            disabled={isSending.get() || isLoading.get()}
            onClick={() => openState.set(false)}
            variant='outlined'
          >
            <Typography variant='button'>
              {translate('Cancel')}
            </Typography>
          </Button>
          {canTransferDevice.get() && (
            <LoadingButton
              type='submit'
              loading={isSending.get()}
              variant='contained'
              disabled={isSending.get() || isLoading.get()}
            >
              {translate('Submit')}
            </LoadingButton>
          )}

          {(!canTransferDevice.get() && isShowCancelScheduleButton.get()) && (
            <LoadingButton
              disabled={isSending.get() || isLoading.get()}
              onClick={onCancelScheduleTransfer}
              variant='contained'
              loading={isSending.get()}
            >
              {translate('Cancel Appointment')}
            </LoadingButton>
          )}
        </DialogActions>
      </form >
    </Dialog>
  )
}