import { useHookstate } from '@hookstate/core';
import { Button, Box, Typography, Stack, ListItem, ListItemText, Divider, List, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency';
import FilterAutocompleteOperation from '~/components/FilterAutocompleteOperation';
import StandardModal from '~/components/StandardModal';
import type ScreenSettingsDraggableProps from '~/features/System/interfaces/ScreenSettings';
import { storeScreenSettings } from '~/features/System/services';
import useSystemState from '~/features/System/stores/SystemState';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import { getMarkersDeviceAllService } from '../../services';
import useDeviceState from '../../stores/DeviceState';
import { useMarkerState } from '~/features/Map/stores/MapState';
import { elapsedTime, elapsedTimeInMinutes } from '~/utils/dateTime';
import dayjs from 'dayjs';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { LatLng } from 'leaflet';

interface filteredDevicesConnectedsProps {
  readonly id: number,
  readonly identifier: string;
  readonly date: string;
  readonly position: {
    readonly lat: string;
    readonly lng: string;
  }
}

const modal = document.getElementById('div-modal') as HTMLElement;

// eslint-disable-next-line max-lines-per-function
export default function MapListConnectedDevicesModalDraggable() {
  const { translate } = useLang();
  const { forceViewMap } = useMarkerState();
  const markersState = useMarkerState().markersState();
  const { windowManager } = useSystemState();
  const loadingComponent = useHookstate(true)
  const abort = new AbortController();
  const filteredDevicesConnecteds = useHookstate<filteredDevicesConnectedsProps[]>([])
  const countLaggedDevices = useHookstate<{ overFive: string[], underFive: string[] }>({
    overFive: [],
    underFive: []
  })

  const changeColorTime = (elapsedMinutes) => {
    if (elapsedMinutes <= 5) {
      return '#4caf50';
    } else if (elapsedMinutes > 5 && elapsedMinutes <= 10) {
      return '#ffc107'
    } else if (elapsedMinutes > 10) {
      return '#f44336';
    }
  }


  const getPositionsDraggable = useHookstate<ScreenSettingsDraggableProps>({
    id: 0,
    user_id: 0,
    url: '',
    window_name: '',
    x_position: 80,
    y_position: 141,
    is_window_open: true,
    is_window_minimized: false,
    created_at: new Date().toJSON(),
    updated_at: new Date().toJSON(),
    deleted_at: null
  })

  useEffect(() => {
    authFetch({
      url: '/screen-setting/get-user-settings',
      method: 'POST',
      data: {
        url: location.pathname,
      }
    }).then((response) => {
      if ('MapListConnectedDevicesModalDraggable' in response.data) {
        getPositionsDraggable.set(response.data.MapListConnectedDevicesModalDraggable)
      }
      loadingComponent.set(false)
    })

    const filteredArray = Object.values(markersState['device'].get()).filter((item: any) => {
      const status = Number(item.status);
      return status >= 1 && status < 8;
    })

    filteredDevicesConnecteds.set(JSON.parse(JSON.stringify(filteredArray)))

    filteredDevicesConnecteds.get().map((item: any) => {
      const diffMinutes = dayjs(new Date()).diff(item.date, 'm')

      diffMinutes > 5 ? countLaggedDevices.overFive.merge([item.identifier]) : countLaggedDevices.underFive.merge([item.identifier])
    })

    return () => {
      windowManager()['mapListConnectedDevices'].open.set(false)
    }
  }, [])


  useEffect(() => {
    return () => {
      storeScreenSettings({
        windowName: 'MapListConnectedDevicesModalDraggable',
        isOpen: windowManager()['mapListConnectedDevices'].open.get(),
        isMinimized: windowManager()['mapListConnectedDevices'].minimized.get(),
        positionX: windowManager()['mapListConnectedDevices'].positionX.get(),
        positionY: windowManager()['mapListConnectedDevices'].positionY.get(),
      })

      abort.abort();
    }
  }, [
    windowManager()['mapListConnectedDevices'].open.get(),
    windowManager()['mapListConnectedDevices'].minimized.get(),
    windowManager()['mapListConnectedDevices'].positionY.get(),
    windowManager()['mapListConnectedDevices'].positionX.get(),
  ])


  const handleStop = (event, dragElement) => {
    event.stopPropagation()

    windowManager()['mapListConnectedDevices'].merge({
      positionX: dragElement.x,
      positionY: dragElement.y,
    })
  }

  return ReactDOM.createPortal(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {windowManager()['mapListConnectedDevices'].open.value && !loadingComponent.get() &&
        <StandardModal
          title={translate('List connected devices')}
          subheader={
            <>
              <Typography variant='subtitle2'>
                {translate('Devices delayed for over five minutes')}: {countLaggedDevices.overFive.get().length}
              </Typography>
              <Typography variant='subtitle2'>
                {translate('Devices delayed for under five minutes')}: {countLaggedDevices.underFive.get().length}
              </Typography>
            </>
          }
          handleStop={handleStop}
          position={{
            x: windowManager()['mapListConnectedDevices'].positionX?.get() ? Number(windowManager()['mapListConnectedDevices'].positionX?.get()) : 80,
            y: windowManager()['mapListConnectedDevices'].positionY?.get() ? Number(windowManager()['mapListConnectedDevices'].positionY?.get()) : 141,
          }}
          closeButton={() => windowManager()['mapListConnectedDevices'].open.set(false)}
          isExpanded={windowManager()['mapListConnectedDevices'].minimized}
          dimensions={{
            width: 430,
            height: 430,
          }}
        >
          <Box width={'100%'} display={'flex'} alignItems={'center'} flexDirection={'column'}>
            <Box width={'100%'} display={'flex'} justifyContent={'flex-start'} flexDirection={'column'} gap={1}>
              <List sx={{ padding: 0, overflow: 'auto', maxHeight: 400, }} >
                {
                  // eslint-disable-next-line functional/immutable-data
                  JSON.parse(JSON.stringify(filteredDevicesConnecteds.get()))
                    .sort((a, b) => dayjs(a.date).diff(b.date, "seconds"))
                    .map((item: filteredDevicesConnectedsProps) => {
                      return (
                        <>
                          <ListItem key={item.id} sx={{ paddingX: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <ListItemText
                              primary={item.identifier}
                              secondary={
                                <>
                                  <Typography
                                    component="span"
                                    variant="body2"
                                    sx={{ display: 'inline' }}
                                  >
                                    {`${translate('Last update')}:`}
                                    <span style={{ color: changeColorTime(elapsedTimeInMinutes(dayjs(item.date).toDate())) }}>
                                      {' '}
                                      {dayjs(item.date).format('DD/MM/YYYY HH:mm:ss')}
                                    </span>
                                  </Typography>
                                  <Typography>
                                    {`${translate('Device time delayed', {
                                      timeDelayed: elapsedTime(item.date)
                                    })}`}
                                  </Typography>
                                </>
                              }
                            />
                            <IconButton
                              disabled={!Boolean(item.position)}
                              title={translate('Locate on the map')}
                              sx={{ margin: '0 !important' }}
                              onClick={() => {
                                forceViewMap().zoom.set(17);
                                forceViewMap().position.set(new LatLng(Number(item.position.lat), Number(item.position.lng)));
                              }}
                            >
                              {<TravelExploreIcon />}
                            </IconButton>
                          </ListItem>
                          <Divider component="li"
                            sx={{
                              "&:last-child": {
                                display: 'none',
                              }
                            }} />
                        </>
                        // eslint-disable-next-line id-length
                      )
                    })
                }
              </List>
            </Box>
          </Box>
        </StandardModal >
      }
    </>
    , modal)
}