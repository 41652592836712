import { AppRouteProps } from '~/routes';
import ReportResult from './pages/Result';
import DetailByType from './pages/ReportOccurrence/OccurenceByType';
import ChronologyByOccurrence from './pages/ReportOccurrence/ChronologyByOccurrence';
import OccurrenceByType from './pages/ReportOccurrence/OccurenceByType';
import TypesOccurrencesDetailed from './pages/ReportOccurrence/TypesOccurrencesDetailed';
import SearchEmploymentVehiclesOccurrences from './pages/ReportOccurrence/SearchEmploymentVehiclesOccurrences';
import OccurrenceConsultation from './pages/ReportOccurrence/OccurrenceConsultation';
import OccurrenceHandledByGroup from './pages/ReportOccurrence/OccurrenceHandledByGroup';
import StatusDispatchByDate from './pages/ReportOccurrence/StatusDispatchesByDate';
import SearchMultipleComments from './pages/ReportOccurrence/SearchMultipleComments';
import TotalOccurrencesByClosingStatus from './pages/ReportOccurrence/DispatchByClosedStatus';
import EntryByStatus from './pages/ReportEntry/EntryByStatus';
import ApproachesByUser from './pages/ReportOccurrence/ApproachesByUser';
import TotalEntryByUser from './pages/ReportEntry/TotalEntryByUser';
import TotalPrankCalls from './pages/ReportEntry/TotalPrankCalls';
import ReportInterestPoints from './pages/ReportInterestPoints';
import LocationAccuracy from './pages/ReportEntry/LocationAccuracy';
import TotalQualifiersSearchesByUser from './pages/ReportQualifiers/TotalQualifiersSearchesPerUser';
import OutOfServiceProductivity from './pages/ReportDevices/OutOfServiceProductivity';
import OnMissionProductivity from './pages/ReportDevices/OnMissionProductivity';
import ConnectionLogs from './pages/ReportDevices/ConnectionLog';
import DevicesByDispatchGroupAndAgency from './pages/ReportDevices/DevicesByDispatchGroupAndAgency';
import DeviceByStatus from './pages/ReportDevices/DevicesByStatus';
import DeviceVSDispatches from './pages/ReportDevices/DevicesVSDispatches';
import DeviceTimeline from './pages/ReportDevices/DeviceTimeLine';
import DeviceServices from './pages/ReportDevices/ServiceDevices';
import TotalConnectedDevices from './pages/ReportDevices/TotalConnectedDevices';

// eslint-disable-next-line max-lines-per-function
export default function reportBuilderRoutes(): readonly AppRouteProps[] {
  const routes: readonly AppRouteProps[] = [
    {
      path: '/report/result/:reportName',
      component: ReportResult,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/occurrence-by-type',
      component: OccurrenceByType,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/devices-dispatch-group-agency',
      component: DevicesByDispatchGroupAndAgency,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/connection-logs',
      component: ConnectionLogs,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/chronology-occurrence',
      component: ChronologyByOccurrence,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/types-occurrences-detailed',
      component: TypesOccurrencesDetailed,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/employment-vehicles-occurrences',
      component: SearchEmploymentVehiclesOccurrences,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/occurrence-consultation',
      component: OccurrenceConsultation,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/occurrences-handled-by-group',
      component: OccurrenceHandledByGroup,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/devices-by-status',
      component: DeviceByStatus,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/device-services',
      component: DeviceServices,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/dispatches-status-by-date',
      component: StatusDispatchByDate,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/search-multiple-coments',
      component: SearchMultipleComments,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/dispatch-by-closed-status',
      component: TotalOccurrencesByClosingStatus,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/devices-vs-dispatches',
      component: DeviceVSDispatches,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/connected-devices',
      component: TotalConnectedDevices,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/entry-by-status',
      component: EntryByStatus,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/approaches-by-user',
      component: ApproachesByUser,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/total-entry-by-user',
      component: TotalEntryByUser,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/total-prank-calls',
      component: TotalPrankCalls,
      isProtected: true,
      permissions: []
    },   
    {
      path: '/report/interest-points-registered',
      component: ReportInterestPoints,
      isProtected: true,
      permissions: []
    },  
    {
      path: '/report/device-timeline',
      component: DeviceTimeline,
      isProtected: true,
      permissions: []
    },
    {
      path: '/report/total-entry-by-accuracy',
      component: LocationAccuracy,
      isProtected: true,
      permissions: ['report_entry_location_accuracy']
    },
    {
      path: '/report/total-qualifiers-searches-by-user',
      component: TotalQualifiersSearchesByUser,
      isProtected: true,
      permissions: ['report_qualifiers_searches_per_user']
    },
    {
      path: '/report/out-of-service-productivity',
      component: OutOfServiceProductivity,
      isProtected: true,
      permissions: ['report_device_out_of_service_productivity']
    },
    // {
    //   path: '/report/on-mission-productivity',
    //   component: OnMissionProductivity,
    //   isProtected: true,
    //   permissions: ['report_device_on_mission_productivity']
    // }
  ];
  return routes;
}
