import React from 'react'
import { useHookstate } from '@hookstate/core'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import Map from '~/features/Map/components/Map'
import { useLang } from '~/hooks/useLang'
import HeatMapDispatchLayer from '../../components/HeatMapDispatchLayer'
import FilterDispatchHotMap from '../FilterDispatchHotMap'

export default function DispatchHotMap({
  agencyId,
  deviceId,
  operationId,
  dispatchGroupId,
  category,
  type,
  subtype,
  dateStart,
  dateFinish,
  thirdParty,
  refresh,
  consummated,
  statusIds,
  cityIds,
  groupSetId,
  location,
  closedStatusClassId,
  closedStatusId,
  qualifierOptions,
  expandFilterSearch,
  operationUnitId,
}) {
  const { translate } = useLang()
  const locationAccuracy = useHookstate<readonly number[]>([])
  const refreshHotMap = useHookstate(false)
  const loading = useHookstate(false)

  return (
    <Grid
      container
      xs={12}
      sx={{
        display: 'flex',
        paddingX: 3,
        paddingTop: 3,
      }}
    >
      <Card variant="outlined" sx={{ width: '100%', overflowX: 'auto' }}>
        <CardContent sx={{ height: '80%' }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 16 }}>
              {translate('Dispatch Heat Map')}
            </Typography>

          <FilterDispatchHotMap expandFilterSearch={expandFilterSearch} locationAccuracy={locationAccuracy} refreshHotMap={refreshHotMap} loading={loading} />
          <Grid item xs={12} hidden={!expandFilterSearch} padding={4}>
            <Map enableLayerControl={false}>
              <HeatMapDispatchLayer
                agencyId={agencyId}
                operationId={operationId}
                deviceId={deviceId}
                dispatchGroupId={dispatchGroupId}
                category={category}
                type={type}
                subtype={subtype}
                dateStart={dateStart}
                dateFinish={dateFinish}
                thirdParty={thirdParty}
                refresh={refresh}
                consummated={consummated}
                statusIds={statusIds}
                cityIds={cityIds}
                groupSetId={groupSetId}
                location={location}
                closedStatusClassId={closedStatusClassId}
                closedStatusId={closedStatusId}
                qualifierOptions={qualifierOptions}
                operationUnitId={operationUnitId}
                locationAccuracy={locationAccuracy.get()}
                refreshHopMap={refreshHotMap.get()}
                loading={loading}
              />
            </Map>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
