import React, { useEffect } from 'react';
import { Button, Card, CardHeader, Collapse, Grid, IconButton } from '@mui/material';
import { ErrorFallback } from 'bizcharts/lib/boundary/ErrorBoundary';
import { ErrorBoundary } from 'react-error-boundary';
import Layout from '~/components/Layout';
import Loading from '~/components/Loading';
import { useLang } from '~/hooks/useLang';
import { downloadReportCSV } from '../../../services/report';
import { useHookstate } from '@hookstate/core';
import useReportState from '../../../stores/ReportState';
import FilterAutocompleteDispatchGroup from '~/components/FilterAutocompleteDispatchGroup';
import FilterAutocompleteAgency from '~/components/FilterAutocompleteAgency';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GenerateReportButton from '../../../components/GenerateReportButton';
import ExportButtonCSV from '../../../components/ButtonExportCSV';
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups';
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies';
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet';
import { makeRequestToGetDevicesByDispatchGroupAndAgency } from './services';

// eslint-disable-next-line max-lines-per-function
export default function DevicesByDispatchGroupAndAgency() {
  const { translate } = useLang();
  const isLoading = useHookstate(false);
  const { reportData, loadingReport } = useReportState();
  const agencyIds = useHookstate<readonly number[]>([]);
  const lockFields = useHookstate<boolean>(false);
  const groupsId = useHookstate<readonly number[]>([]);
  const expandFilterSearch = useHookstate<boolean>(true);
  const reportName = 'devices-dispatch-group-agency'
  const groupSetId = useHookstate<number | null>(null)

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])


  async function generateReport(exportCSV = false) {
    loadingReport().set(true)
    !exportCSV && loadingReport().set(true)
    const data = await makeRequestToGetDevicesByDispatchGroupAndAgency({
      agencyId: agencyIds.get(),
      dispatchGroupId: groupsId.get(),
      groupSetId: groupSetId.get(),
      exportCSV: exportCSV
    }
    ).finally(() => {
      loadingReport().set(false)
      isLoading.set(false)
    })

    if (exportCSV) {
      downloadReportCSV(
        `${translate(reportName)}-${new Date().getTime()}.csv`,
        data
      )
    } else {
      setTimeout(() => {
        reportData().set(data)
      })
      window.open(`/report/result/${reportName}`)
    }
  }

  const clearFilter = () => {
    agencyIds.set([])
    groupSetId.set(null)
    groupsId.set([])
}

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true} >
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader title={`${translate('Report')}: ${translate(`List of Devices`)}`} />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Filters')}`}
            avatar={(
              <IconButton
                onClick={() => expandFilterSearch.set(!expandFilterSearch.get())}
                sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
                {expandFilterSearch.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
              </IconButton>
            )}
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    disabled={lockFields.get()}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => agencyIds.set(agency)} />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchGroupSet
                    disabled={lockFields.get()}
                    dispatchGroupSetId={groupSetId?.get() ?? undefined}
                    onGroupDispatchSetChange={(group) => {
                      groupSetId?.set(group)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get() ?? undefined}
                    dispatcheGroups={groupsId.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupsId.set(group)
                    }}
                    groupSetId={groupSetId.get()}
                  />
                </Grid>

              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2
                }}>
                <Button variant="outlined"
                  onClick={() => clearFilter()}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={() => generateReport(true)}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={generateReport}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary >
  )
}