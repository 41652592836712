import { authFetch } from "~/services/fetch";

export async function makeRequestToGetReporDevicesByStatus({ 
    momentDate, 
    dispatchGroupsId, 
    agencyIds, 
    deviceIds, 
    exportCSV = false,
    groupSetId
}) {
    try {
        const response = await authFetch({
            url: '/report/devices-by-status',
            method: 'POST',
            data: {
                date_time: momentDate,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                export_csv: exportCSV,
                device_ids: deviceIds,
                group_set_id: groupSetId,
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}