/* eslint-disable functional/prefer-readonly-type */
import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { useHookstate, type State } from '@hookstate/core';
import { DispatchGroupResponse } from './interface';
import { getDispatchGroup } from './service';

interface FilterAutocompleteDispatcheGroupsProps {
    readonly onGroupDispatchChange: (value: number | null) => void;
    readonly dispatchGroupId?: number;
    readonly disabled?: boolean;
    readonly agencyId?: number | null;
    readonly label?: string;
    readonly isNonOperation ?: boolean
    readonly error?: State<string | null | undefined>;
}

export default function FilterAutocompleteDispatchGroup({ agencyId, onGroupDispatchChange, dispatchGroupId, label = 'Dispatch Group', disabled = false, error, isNonOperation }: FilterAutocompleteDispatcheGroupsProps) {
    const { translate } = useLang();
    const [options, setOptions] = useState<readonly DispatchGroupResponse[]>([]);
    const [optionsFilter, setOptionsFilter] = useState<readonly DispatchGroupResponse[]>([]);
    const [value, setValue] = useState<DispatchGroupResponse | null>(null);
    const loading = useHookstate(false);

    useEffect(() => {
        getDispatchGroup().then(dispatchGroup => {
            const dataFiltered = dispatchGroup.filter((dispatchGroudItem) => !dispatchGroudItem.is_non_operational)
            isNonOperation ? dataFiltered : dispatchGroup
            setOptions(isNonOperation ? dataFiltered : dispatchGroup)
            setOptionsFilter(isNonOperation ? dataFiltered : dispatchGroup)
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (agencyId) {
            const filter = options.filter(typeMap => typeMap.agency_id === agencyId)
            setOptionsFilter(filter.length ? filter : [])
        } else {
            setOptionsFilter(options)
        }

    }, [agencyId, options])

    useEffect(() => {
        if (!dispatchGroupId) {
            setValue(null)
        } else {
            setValue(optionsFilter.find(option => dispatchGroupId === option.id) ?? null)
        }
    }, [dispatchGroupId, optionsFilter])

    return (
        <FormControl
            fullWidth
            size='small'
        >
            <Autocomplete
                id='filter-dispatch-group'
                disabled={disabled}
                limitTags={2}
                sx={{ height: 2 }}
                options={optionsFilter}
                value={value}
                noOptionsText={translate('No options')}
                clearText={translate('Clear')}
                size='small'
                loading={loading.get()}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name ?? ''}
                onChange={(_, type) => {
                    setValue(type)
                    onGroupDispatchChange(type?.id ?? null)
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate(label)}
                        error={!!error?.get()}
                        helperText={error?.get()}
                    />
                )}
            />
        </FormControl>
    )
}