import React, { useEffect, useMemo, useState } from 'react';
import { useLang } from '../../../../../../hooks/useLang';
import useMessageState from '../../../../stores/MessageState';
import { FormControl, Fab, TextField, Paper, MenuItem, Autocomplete, Grid, Button, Divider, Tooltip, Box, CircularProgress } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';
import { truncateText } from '~/utils/strings';
import { authFetch } from '~/services/fetch';
import IUser from '~/features/User/interfaces/IUser';
import FilterAutocompleteFetch from '~/components/FilterAutocompleteFetch';
import { useHookstate } from '@hookstate/core';

type TypeUserReceivers = {
  readonly active: number
  readonly agency: { id: number, name: string, acronym: string }
  readonly agency_id: number
  readonly birth_date: string
  readonly blood_type: string
  readonly cpf: string
  readonly created_at: string
  readonly deleted_at: null
  readonly email: string
  readonly emergency_contact_name: string
  readonly emergency_contact_phone: string
  readonly id: number
  readonly is_connected: number
  readonly login: string
  readonly name: string
  readonly phone: string
  readonly registration: null | string
  readonly updated_at: string
}
type TypeStationReceivers = {
  readonly agency: { id: number, name: string, acronym: string },
  readonly agency_id: number,
  readonly authentication_key: string,
  readonly created_at: string,
  readonly deleted_at: null | string,
  readonly id: number,
  readonly identification_key: null | string,
  readonly mac_address: string,
  readonly name: string,
  readonly serial_hd: string,
  readonly updated_at: string,
  readonly verification_key: null
}
type TypeVehiclesReceivers = {
  readonly agency: { id: number, name: string, acronym: string },
  readonly agency_id: number,
  readonly created_at: string,
  readonly deleted_at: null,
  readonly device_id: number,
  readonly dispatch_group_id: number,
  readonly id: number,
  readonly identification: string,
  readonly plate: string,
  readonly updated_at: string | null,
  readonly vehicleType: { id: number, name: string },
  readonly vehicle_status_id: null,
  readonly vehicle_type_id: number
}

export type ListReceivers = TypeUserReceivers[] | TypeStationReceivers[] | TypeVehiclesReceivers[] | any[];

// eslint-disable-next-line max-lines-per-function
export default function NewMessage() {
  const { newMessage, sendNewMessage, listReceiver, syncListReceiver, selectedMessageUser } = useMessageState()
  const { translate } = useLang()
  const [term, setTerm] = useState<string>('')
  const [option, setOption] = useState<IUser[]>([]);
  const [value, setValue] = useState<IUser | null>(null);
  const [loading, setLoading] = useState(false);
  const [resetValeus, setResetValues] = useState(false)
  const characterValid = useHookstate<number>(0);
  const messageHelpText = useHookstate<string>('Enter a message with a minimum of 5 valid characters');
  const messageError = useHookstate<boolean>(false)

  // const momoedOptions = useMemo(() => {
  //   if (term.length > 3) {
  //     return listReceiver().get().filter(element => Boolean(element?.representation_name))
  //   }
  // }, [listReceiver().get()])

  // useEffect(() => {
  //     newMessage().receiverType.set('/user/get');
  //     syncListReceiver();
  // }, [])

  function handleMinCharacter(str: string) {
    characterValid.set(0)
    const regex = /^[A-Za-z]+$/

    str.split('').map(item => {
      if (regex.test(item)) {
        characterValid.set(characterValid.get() + 1)
      } 
    })

    return characterValid.get() >= 5 ? true : false
  }

  const handleSendMessage = () => {
    if(!newMessage().message.get() || !handleMinCharacter(newMessage().message.get())){
      messageError.set(true)
    } else {
      sendNewMessage()
      setResetValues(true)
    }
  }

  const fetchUser = React.useMemo(() => {
    if ((term != '' && term.length >= 3) || selectedMessageUser().get()?.id != null) {
      authFetch({
        url: '/user/get',
        method: 'POST',
        data: {
          user: term
        }
      }).then((response) => {
        if (response.status === 200) {
          setOption(response.data)
          setLoading(false)
          return response.data
        }
      })
    }
  }, [term])

  useEffect(() => {
    if (selectedMessageUser().get()?.id != null) {
      newMessage().receiver.id.set(JSON.parse(JSON.stringify(Number(selectedMessageUser().get()?.id))))
      fetchUser
    }

    if (term.length >= 3 && term != '') {
      fetchUser
    }
  }, [term, fetchUser])

  return (
    <Grid container columns={12} direction={'column'} sx={{ height: '100%' }}>
      <Grid item md={1} xs={1} sx={{ display: 'flex', justifyContent: 'start', gap: 2 }} >
        <FormControl
          size="small"
          sx={{ minWidth: 140 }}
        >
          <TextField
            size='small'
            value={newMessage().receiverType.get()}
            onChange={(event) => {
              newMessage().receiverType.set(event.target.value);
              syncListReceiver();
            }}
            select
            label={translate('Category')}
          >
            <MenuItem value='user'>
              {translate('User')}
            </MenuItem>
            {/* <MenuItem value='station'>
              {translate('Station')}
            </MenuItem>
            <MenuItem value='vehicle'>
              {translate('Vehicle')}
            </MenuItem> */}
          </TextField>
        </FormControl>
        {/* <FormControl size="small" fullWidth> */}

        <FilterAutocompleteFetch
          label={'Receiver'}
          urlFetch={'/user/get'}
          size={"small"}
          disabled={Boolean(!newMessage().receiverType.get())}
          onFetchChange={(user) => user ? newMessage().receiver.id.set(user) : null}
          resetValues={resetValeus}
          model='user'
          valueId={newMessage().get().receiver.id ? Number(newMessage().get().receiver.id) : undefined}
        />

        {/* <Autocomplete
            // value={newMessage().receiver.id.get() ? momoedOptions.find(element => element.id === newMessage().receiver.id.get()) : null}
            options={option}
            value={value}
            noOptionsText={translate('No Receivers')}
            loading={loading}
            size='small'
            // onChange={(_, value) => {
            //   if (!value?.id) return;
            //   newMessage().receiver.id.set(value.id);
            // }}
            onChange={(_, user) => {
              setValue(user)
              newMessage().receiver.id.set(user ? user.id : null)
            }}
            // options={newMessage().receiverType.get() ? momoedOptions : []}
            // getOptionLabel={(option) => option.registration + option.representation_name}
            getOptionLabel={(option) => `[${option?.registration}] - ${option?.representation_name}`}
            inputValue={term}
            onInputChange={(event, newInputValue) => {
              setTerm(newInputValue);
            }}
            // renderOption={(props, option) => (
            //   <Box component='li' {...props} key={option.id}>
            //     <Tooltip title={option.representation_name} placement="left">
            //       <Box>
            //         {truncateText(`${option.registration ? option.registration : 'SN'} - ${option.representation_name || ''}`, 18)}
            //       </Box>
            //     </Tooltip>
            //   </Box>
            // )}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate('Receiver')}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            // isOptionEqualToValue={(option: any, value) => {
            //   if ((!option) || (!value)) return false;
            //   return option.id === value.id;
            // }}
            // renderInput={
            //   (props) => {
            //     return (
            //       <TextField  {...props} label={translate('Receiver')} size='small' />
            //     )
            //   }
            // }
          /> */}
        {/* </FormControl> */}
      </Grid>

      <Grid item md xs sx={{ height: '100%', marginY: 1.2 }}>
        <TextField
          multiline
          rows={13}
          error={messageError.get()}
          sx={{ width: '100%', height: '100%' }}
          placeholder={translate('Write something')}
          value={newMessage().message.get()}
          onChange={(event) => {
            handleMinCharacter(event.target.value) && messageError.set(false)
            newMessage().message.set(event.target.value)
          }}
          variant="outlined"
          helperText={translate(messageHelpText.get())}
    
        />
      </Grid>
      <Grid item md xs sx={{ height: '100%', backgroundColor: 'red' }}>
      <Button
        variant='contained'
        size="small"
        color="primary"
        onClick={() => handleSendMessage()}
        sx={{ position: 'absolute', bottom: 17, right: 30, borderRadius: '4px', marginTop: 1 }}
      >
        {translate('Send')}
      </Button>
      </Grid>
      
    </Grid>
  )
}
