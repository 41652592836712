/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Button, Card, CardHeader, Collapse, Grid, IconButton } from '@mui/material'
import ErrorFallback from '~/components/ErrorFallback'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'
import notify from '~/utils/notify'
import { authFetch } from '~/services/fetch'

import { useHookstate } from '@hookstate/core'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'
import FilterAutocompleteDevice from '~/components/FilterAutocompleteDevice'
import useReportState from '~/features/Report/stores/ReportState'
import ExportButtonCSV from '~/features/Report/components/ButtonExportCSV'
import GenerateReportButton from '~/features/Report/components/GenerateReportButton'
import { makeRequestToGetDeviceTimeline } from './services'
import { downloadReportCSV } from '~/features/Report/services/report'

export default function DeviceTimeline() {
  const { translate } = useLang()
  const isLoading = useHookstate<boolean>(false)
  const expandFilterSearch = useHookstate<boolean>(true)
  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const groupsId = useHookstate<readonly number[]>([])
  const agencyIds = useHookstate<readonly number[]>([])
  const devicesId = useHookstate<number | null>(null)
  const labelButton = useHookstate('')
  const lockFields = useHookstate<boolean>(false)
  const period = useHookstate('')
  const reportName = 'device-timeline'
  const {
    reportData,
    loadingReport,
    dispatchGroups: { dispatchGroup },
  } = useReportState()
  const groupSetId = useHookstate<number | null>(null)

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  useEffect(() => {
    const getDispatchGroups = async () => {
      authFetch({
        url: '/station/dispatch-groups/me',
        method: 'GET',
        data: {},
      }).then((response) => {
        dispatchGroup.options.merge(response.data)
      })
    }

    getDispatchGroups()
  }, [])

  async function generateReport(exportCSV = false) {
    if (startDate && finishDate) {
      isLoading.set(true)
      !exportCSV && loadingReport().set(true)
      const data = await makeRequestToGetDeviceTimeline({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        deviceId: devicesId.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupsId.get(),
        groupSetId: groupSetId.get(),
        exportCSV: exportCSV
      }).finally(() => {
        loadingReport().set(false)
        isLoading.set(false)
      })

      if (exportCSV) {
        downloadReportCSV(
          `${translate(reportName)}-${new Date().getTime()}.csv`,
          data
        )
      } else {
        setTimeout(() => {
          reportData().set(data)
        })
        window.open(`/report/result/${reportName}`)
      }
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  const clearFilters = () => {
    agencyIds.set([])
    groupSetId.set(null)
    groupsId.set([])
    devicesId.set(null)
    period.set('')
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  `Device timeline`
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container
              xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    disabled={lockFields.get()}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => {
                      labelButton.set(translate('Search'))
                      agencyIds.set(agency)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchGroupSet
                    disabled={lockFields.get()}
                    dispatchGroupSetId={groupSetId.get() ?? undefined}
                    onGroupDispatchSetChange={(group) => {
                      groupSetId.set(group)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get() ?? undefined}
                    dispatcheGroups={groupsId.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupsId.set(group)
                      labelButton.set(translate('Search'))
                    }}
                    groupSetId={groupSetId?.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDevice
                    deviceId={devicesId.get()}
                    onDeviceChange={(device) => {
                      devicesId.set(device)
                    }}
                    disabled={lockFields.get()}
                    groupDispatchs={groupsId.get()}
                  />
                </Grid>
                <Grid container direction={'row'}>
                  <FilterSelectPeriod
                    disabled={lockFields.get()}
                    onStartDateChange={(value) =>
                      startDate.set(value?.toJSON() ?? null)
                    }
                    onFinishDateChange={(value) =>
                      finishDate.set(value?.toJSON() ?? null)
                    }
                    onPeriodChange={(value) => {
                      period.set(value)
                    }}
                    period={period.get()}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => clearFilters()}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={() => generateReport(true)}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={() => generateReport()}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary>
  )
}
