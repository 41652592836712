/* eslint-disable camelcase */
/* eslint-disable id-length */
import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { BarChart } from '@mui/x-charts/BarChart'
import { AxisConfig, ChartsXAxisProps } from '@mui/x-charts'
import { AxisScaleConfig, MakeOptional } from '@mui/x-charts/internals'
import { truncate } from '~/features/Dispatch/utils/stringsManipulations'
import { convertSecondsToTime } from '~/utils/dateTime'

export default function ColumnChartVertical({
  data,
  title,
  subtitle = '',
  sxFont = {},
  colorBar = 'primary',
  typeTotal = '',
  scaleType = 'band',
  hasOtherLabel = false,
  Ytype = 'number',
  marginLeft = 70,
  tooltip = '',
  width=0,
  valueFormatter = (value: any, context: { location: string }) => context.location === 'tick' ? truncate(String(value), 18) : value,
  hasParentWidth = false
}) {
  const { translate } = useLang()

  const getColor = {
    primary: '#6395FA',
    secondary: '#59BD8E',
    tertiary: '#EDAE49',
  }

  const xFieldTreatament = data.map((element) => {
    return String(element.item).replaceAll(' ', '\n')
  })

  const dataTreatament = data.map((element) => {
    return element.value
  })

  return (
    <Card variant="outlined" sx={{ height: 600, overflowX: 'auto' }}>
      <CardContent sx={{ height: '80%' }}>
        <Typography sx={{ fontWeight: 'bold', ...sxFont }}>
          {translate(title)}
        </Typography>
        {subtitle && (
          <Typography variant="subtitle2" marginBottom={2} marginTop={1}>
            {translate(subtitle)}{' '}
            {typeTotal &&
              `| ${translate('Total of')} ${translate(typeTotal)}: ${
                data.length
              }`}
          </Typography>
        )}
        {data.length ? (
          <Grid>
            <BarChart
              series={[{ data: dataTreatament, valueFormatter: (value, {dataIndex}) => {
                return tooltip ? `${tooltip} ${data[dataIndex].tooltipInfo}` : `${value}`
              }}]}
              xAxis={[
                {
                  scaleType: scaleType,
                  data: xFieldTreatament,
                  categoryGapRatio: 0.5,
                  tickPlacement: 'middle',
                  tickLabelPlacement: 'tick',
                  valueFormatter: valueFormatter,
                  tickLabelStyle: {
                    fontSize: xFieldTreatament.length >= 10 ? 11 : 12,
                    angle: 40,
                    textAnchor: 'start',
                  },
                  colorMap: {
                    type: 'piecewise',
                    thresholds: [0],
                    colors: [getColor[colorBar]],
                  },
                } as unknown as MakeOptional<
                  AxisConfig<keyof AxisScaleConfig, number, ChartsXAxisProps>,
                  'id'
                >,
              ]}
              yAxis={[
                { id: 'linearAxis', scaleType: 'linear', tickMinStep: 1, valueFormatter(value, context) {
                  return Ytype == 'time' ? `${convertSecondsToTime(value)}` : value
                }, },
              ]}
              height={500}
              skipAnimation
              width={
                hasParentWidth ? undefined : width ? width :
                xFieldTreatament.length *
                (xFieldTreatament.length >= 5 ? 130 : 250)
              }
              grid={{ horizontal: true }}
              margin={{ top: 25, left: marginLeft, bottom: 100 }}
              barLabel={(item) => {
                // eslint-disable-next-line new-cap
                return hasOtherLabel ? data[item.dataIndex].label : Intl.NumberFormat('pt-BR').format(Number(item.value))
              }}
              leftAxis={{ labelStyle: { fontSize: 10 } }}
              slotProps={{
                barLabel: { y: -25, style: { fontSize: 16 } },
              }}
              
            />
          </Grid>
        ) : (
          <Grid item sx={{ height: '100%' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
              }}
            >
              <CardContent>
                <Typography>{translate('No data available')}</Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
