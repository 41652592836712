import React, { useState } from 'react'
import {
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Autocomplete,
  TextField,
} from '@mui/material'
import { useLang } from '~/hooks/useLang'
import { Controller, UseFormGetValues, UseFormSetValue } from 'react-hook-form'

type CrudSelectFieldProps = {
  readonly register: any
  readonly name: string
  readonly control: any
  readonly options: any
  readonly placeholder?: string
  readonly setFormValue: UseFormSetValue<any>
}

export default function CrudSelectField(props: CrudSelectFieldProps) {
  const { translate } = useLang()
  const {
    register,
    name,
    control,
    options,
    placeholder,
    setFormValue,
  } = props

  return (
    <Grid item xs={4}>
      <Controller
        {...register(`${name}`)}
        name={name}
        control={control}
        label={translate(
          placeholder
            ? placeholder
            : `${name.charAt(0).toUpperCase() + name.slice(1)}`
        )}
        render={(props) => (
          <Autocomplete
            {...props}
            options={options}
            getOptionLabel={(option: string) => translate(option)}
            value={props.field.value && options ? options.find(element => element === props.field.value) : null}
            isOptionEqualToValue={(option, value) => option === value}
            onChange={(_, newValue) => {
              setFormValue(name, newValue)
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={translate(
                  placeholder
                    ? placeholder
                    : `${name.charAt(0).toUpperCase() + name.slice(1)}`
                )}
              />
            )}
          />
        )}
      />
    </Grid>
  )
}
