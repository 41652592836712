import { authFetch } from "~/services/fetch";

export async function makeRequestToGetReporTotalConnectedDevices({ momentDate, dispatchGroupsId, agencyIds, withOperationUnit, exportCSV = false, groupSetId }) {
    try {
        const response = await authFetch({
            url: '/report/connected-devices',
            method: 'POST',
            data: {
                date_time: momentDate,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                export_csv: exportCSV,
                with_operation_unit: withOperationUnit,
                group_set_id: groupSetId
            }
        });

        return response.data;

    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}