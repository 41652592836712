import React, { useEffect } from 'react';
import { IconButton, Badge, Grid } from '@mui/material';
import { DirectionsCar, Add } from '@mui/icons-material';
import { useLang } from '~/hooks/useLang';
import { useState } from '@hookstate/core';
import useDispatchState from '~/features/Dispatch/stores/DispatchState';


export default function AddVehicleButtonPopup({ status, id, groupId, readOnly = false }) {
  const { translate } = useLang();
  const { associateVehicleDispatch, dispatch } = useDispatchState();
  const isAwaiting = useState(false);
  const disabled = useState(true);

  const associateVehicleToCurrentDispatch = () => {
    if (id) {
      isAwaiting.set(true);
      associateVehicleDispatch(id)
        .finally(() => setTimeout(() => {
          isAwaiting.set(false)
        }, 1000));
    }
  };

  useEffect(() => {
    disabled.set(status != 1 || dispatch()?.status?.id?.get() == 8 || (groupId != dispatch()?.dispatchGroup?.id?.get() || readOnly))
  }, [status, dispatch()?.status?.id?.get()])

  return (
    <Grid item xs={2} >
      <IconButton
        title={translate('Add Vehicle')}
        color='primary'
        disabled={disabled.get() || isAwaiting.get()}
        onClick={associateVehicleToCurrentDispatch}
      >
        <DirectionsCar />
      </IconButton>
    </Grid>);

}
