import React, { useCallback, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, MenuItem, Autocomplete, TextField } from '@mui/material';
import { useLang } from '~/hooks/useLang';
import { authFetch } from '~/services/fetch';
import useDispatchGroupState from '~/features/DispatchGroup/stores/DispatchGroupState';
import { LoadingButton } from '@mui/lab';
import { useHookstate } from '@hookstate/core';

interface DispatchClosedProps {
  readonly id: number;
  readonly name: string;
  readonly acronym: string;
}

interface dispatchGroupResponse {
  readonly id: number;
  readonly name: string;
  readonly acronym: string;
  readonly priority: string;
}

export default function DispatchCopyDialog({ open, copyDispatch }) {
  const { translate } = useLang()
  const loading = useHookstate<boolean>(false)
  const [dispatchGroupList, setDispatchGroupList] = useState<DispatchClosedProps[]>([]);
  const [dispatchGroup, setDispatchGroup] = useState<dispatchGroupResponse[] | []>([]);
  const { dispatchGroupView } = useDispatchGroupState();

  const getDispatchGroups = useCallback(async () => {
    await authFetch({
      url: '/dispatch/group',
      method: 'GET',
    }).then(({ data }) => {
      const dataFiltered = data.filter((dispatchGroudItem) => !dispatchGroudItem.is_non_operational)
      // setDispatchGroupList(data);
      setDispatchGroupList(dataFiltered);
    })
  }, [])

  useEffect(() => {
    getDispatchGroups();
  }, [])

  const handleClose = () => {
    clearStates();
    open.set(false);
  };

  const clickOutModalClose = () => {
    open.set(false);
  };

  const action = () => {
    const dispatchGroupsIds = dispatchGroup.map(item => item.id)
    if (copyDispatch) {
      copyDispatch({ dispatchGroupsIds: dispatchGroupsIds, loading, stateModal: open })
    }
    setDispatchGroup([]);
  };

  const clearStates = () => {
    setDispatchGroup([]);
  }
  const groupVisibles = Object.keys(dispatchGroupView().get()).filter(item => dispatchGroupView()[item].is_visible.get())
  const options = dispatchGroupList.map((option) => {
    return {
      priority: Boolean(groupVisibles.find((element) => element == option.name)) ? `${translate('Dispatch Groups visibles')}` : `${translate('Others')}`,
      ...option
    }
  })


  return (
    <Dialog open={open.get()} onClose={clickOutModalClose} maxWidth={'xs'} fullWidth>
      <DialogTitle minWidth={"320px"} variant='h5'>
        {translate('Copy dispatch')}
      </DialogTitle>
      <DialogContent>

        <FormControl fullWidth size='small' sx={{ mt: 1 }}>
          <Autocomplete
            filterSelectedOptions
            disableCloseOnSelect
            options={options.sort((a, b) => -b.priority.localeCompare(a.priority))}
            groupBy={(option) => option.priority}
            noOptionsText={translate('No options')}
            size='small'
            multiple
            value={dispatchGroup}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            clearText={translate('Clear')}
            getOptionLabel={(option) => option.name ?? ''}
            onChange={(_, value) => {
              setDispatchGroup(value)
            }}
            renderInput={(params) =>
              <TextField {...params} label={translate('Dispatch Group')}
              />
            }
            renderOption={(props, option) => (
              <MenuItem {...props} sx={{ mt: 1, overflowX: "auto" }} key={option.id} value={option.id}>
                {`[${option.acronym}] ${option.name}`}
              </MenuItem>
            )}
          />
        </FormControl>

      </DialogContent>
      <DialogActions sx={{px: 3, gap: 1}}>
        <Button
          onClick={handleClose}
          variant='outlined'
        >
          {translate('Cancel')}
        </Button>
        <LoadingButton
          loading={loading.get()}
          onClick={action}
          variant='contained'
        >
          {translate('Submit')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}