import { authFetch } from "~/services/fetch";

export async function makeRequestToGetDeviceTimeline({
  startDate,
  finishDate,
  deviceId,
  dispatchGroupsId,
  agencyIds,
  exportCSV = false,
  groupSetId,
}) {
  try {
    const response = await authFetch({
      url: `/report/device-timeline`,
      method: 'POST',
      data: {
        start_date: startDate,
        finish_date: finishDate,
        export_csv: exportCSV,
        device_id: deviceId,
        dispatch_group_ids: dispatchGroupsId,
        agency_ids: agencyIds,
        group_set_id: groupSetId,
      }
    });

    return response.data
  } catch (error) {
    console.error('ERROR GENERATE REPORT', error)
    throw error
  }
}