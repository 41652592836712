import { authFetch } from "~/services/fetch";

export async function makeRequestToGetConnectionLogs({ 
    startDate, 
    finishDate, 
    deviceIds, 
    detailedReport,
    dispatchGroupsId,
    agencyIds,
    exportCSV = false,
    keyWords,
    userId,
    groupSetId
}) {
    try {
        const response = await authFetch({
            url: `/report/connection-logs`,
            method: 'POST',
            data: {
                start_date: startDate,
                finish_date: finishDate,
                export_csv: exportCSV,
                device_ids: deviceIds,
                dispatch_group_ids: dispatchGroupsId,
                agency_ids: agencyIds,
                detailed_report: detailedReport,
                key_words: keyWords,
                user_id: userId,
                group_set_id: groupSetId
            }
        });

        return response.data
    } catch (error) {
        console.error('ERROR GENERATE REPORT', error)
        throw error
    }
}