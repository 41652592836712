/* eslint-disable one-var */
/* eslint-disable functional/prefer-readonly-type */
import React from 'react'
import { Polygon } from 'react-leaflet'
import { useHookstate } from '@hookstate/core'
import { getAreaPlanService } from '~/features/Map/services/request'
import { ErrorBoundary } from 'react-error-boundary'
import useSystemState from '~/features/System/stores/SystemState'
import LabelAreasPlan from '../LabelAreaPlans'

type AreaPlansProps = {
  readonly id: number
  readonly name: string
  readonly areas: readonly {
    readonly id: number
    readonly name: string
    readonly color: string
    readonly acronym: string
    readonly polygon: {
      readonly type:
      | 'Point'
      | 'MultiPoint'
      | 'LineString'
      | 'MultiLineString'
      | 'Polygon'
      | 'MultiPolygon'
      | 'GeometryCollection'
      | 'Feature'
      | 'FeatureCollection'
      readonly coordinates: [number, number][]
    }
  }[]
}

export default function AreaPlans() {
  const areaPlans = useHookstate<readonly AreaPlansProps[]>([])
  const { windowManager } = useSystemState()

  React.useEffect(() => {
    getAreaPlanService().then((data) => {
      areaPlans.set(data)
      data.map((area) => {
        windowManager()['mapSetting']['markersView']['areas']['children'].merge(
          {
            [area.name]: {
              title: area.name,
              show: false,
              filterPropName: 'id',
              filterCondition: () => true,
            },
          }
        )
      })
      windowManager()['mapSetting']['markersView']['areas'].merge({
        hide: true,
        show: false,
      })
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => {
        console.info('AreaPlans', error)
        return <></>
      }}
    >
      {areaPlans.value.map((areaPlan, index) => {
        return (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {windowManager()['mapSetting']['markersView']['areas'][
              'children'
            ].get()
              ? windowManager()['mapSetting']['markersView']['areas'][
                'children'
              ].get()?.[`${areaPlan.name}`]?.['show']
                ? areaPlan.areas.map((area) => {
                  const areas = area.polygon.coordinates[0]
                  const areasMap = JSON.parse(JSON.stringify(areas))
                  if (typeof areasMap == 'object') {

                    return (
                      <>
                        <Polygon
                          positions={[areasMap]}
                          key={area.name}
                          pathOptions={{
                            fillColor: area.color,
                            weight: 2.5,
                            color: area.color,
                            dashOffset: '5',
                            fillOpacity: 0.4,
                            stroke: true,
                          }}
                        />
                        <LabelAreasPlan positions={areasMap} label={area.name} color={area.color} />

                      </>
                    )
                  }
                })
                : false
              : false}
          </>
        )
      })}
    </ErrorBoundary>
  )
}
