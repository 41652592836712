import { IListVehicleSocket, IListVehicleSocketResponse } from "~/components/ListVehiclesSocket/interfaces/IListVehiclesProps"
import { maskPhoneNumber } from "~/features/Dispatch/utils/stringsManipulations";

export function toDomain(row: IListVehicleSocket): IListVehicleSocketResponse {
  const commander = row.device_log?.crew ? row.device_log?.crew.find(crew => crew.position.id === 1)?.user?.representation_name : ''
  const phone = maskPhoneNumber(row.device_log?.phone ?? '');
  const type = `[${row.currentDispatch?.dispatch?.type?.code}] ${row.currentDispatch?.dispatch?.type?.name}`;
  const subtype = row.currentDispatch?.dispatch?.subtype ? `[${row.currentDispatch?.dispatch?.subtype?.code}] ${row.currentDispatch?.dispatch?.subtype?.name}` : '';

  return {
    id: row.id ?? null,
    lockTemporaryTracking: row.lock_temporary_tracking ? row.lock_temporary_tracking : false,
    trackingId: row.tracking_id ? row.tracking_id : null,
    identification: row.name ? row.name : (row.vehicle ? (row.vehicle.identification ? row.vehicle.identification : '-') : '-'),
    statusId: row.status ? row.status.id : '-',
    status: row.status ? row.status.acronym : '-',
    statusChangedAt: row.currentDispatch?.status_changed_at ?? '',
    dispatchGroup: row.dispatch_group ? row.dispatch_group.acronym : '-',
    dispatchGroupId: row.dispatch_group ? row.dispatch_group.id : '-',
    dispatchStatus: row.currentDispatch?.dispatch?.status?.id ?? '',
    dispatchId: row.currentDispatch?.dispatch?.id ?? '',
    dispatch: row.currentDispatch?.dispatch?.code ?? '',
    location: row.currentDispatch?.dispatch?.occurrence?.location ?? '',
    plate: row.vehicle && row.vehicle.plate ? row.vehicle.plate : '',
    outOfServiceReason: row.out_of_service?.reason.name ? row.out_of_service.reason.name : '',
    outOfServiceDate: row.out_of_service?.out_of_service_date ?? '',
    task: row.device_log?.task ? row.device_log?.task : '',
    notes: row.device_log?.notes ? row.device_log?.notes : '',
    availableDate: row.device_log?.date_connected ?? '',
    disconnectedDate: row.device_log?.date_disconnected ?? null,
    commander: commander ?? '',
    phone: phone ?? '',
    comment: row.out_of_service?.comment ? row.out_of_service?.comment : '',
    subtype: subtype ?? '',
    type: type ?? '',
    deviceType: row.device_type ?? null,
    agencyId: row.agency?.id,
  }
}
