import React from 'react';
import { createState, useState } from '@hookstate/core';
import IDisconnectMultipleVehicle, { ListVehiclesProps } from '../interfaces/IListVehiclesProps';
import { authFetch } from '~/services/fetch';

const defaultListVehiclesProps: ListVehiclesProps = {
  canConnectDevice: false,
  canDisconnectDevice: false,
  canTransferDevice: false,
  canSeeCrew: false,
  canEditCrew: false,
  canSetOutOfService: false,
  canSetReturnToService: false,
  openDisconnectDialog: false,
  openCrewDialog: false,
  openConectDispatchVehicle: false,
  openOutOfServiceDialog: false,
  openTransferDevice: false,
  openChronologyVehicle: false,
  openReturnToServiceDialog: false,
  isOpenDialog: false,
  lockTemporaryTracking: false,
  openModalResultMultipleDisconnectVehicle: false,
  trackingId: null,

  plate: null,
  identification: '',
  deviceId: null,
  agencyId: null,
  labelCommander: null,
  labelDriver: null,
  labelPatrolmans: null,
  labelKmInitial: null,
  dispatchGroup: null,
  dispatchGroupId: null

}

const listVehiclesDefaultState = createState<ListVehiclesProps>(Object.create(defaultListVehiclesProps))
const listDevicesDisconnectMultipleSocketStore = [];

const store = createState<IDisconnectMultipleVehicle>({
  listDevicesDisconnectMultipleSocket: listDevicesDisconnectMultipleSocketStore,
});

const useListVehiclesSocket = () => {
  const listVehiclesState = useState(listVehiclesDefaultState);
  const listVehiclesMultipleDisconnect = useState(store.listDevicesDisconnectMultipleSocket);

  return ({
    listVehiclesState: () => listVehiclesState,
    listVehiclesMultipleDisconnect: () => listVehiclesMultipleDisconnect,
    openModalResultMultipleDisconnectVehicle: () => listVehiclesDefaultState.openModalResultMultipleDisconnectVehicle,
    handleMenuActions: (row) => {
      listVehiclesState.identification.set(row.identification);
      listVehiclesState.lockTemporaryTracking.set(row.lockTemporaryTracking);
      listVehiclesState.deviceId.set(row.id);
      listVehiclesState.agencyId.set(row.agencyId);
      listVehiclesState.canEditCrew.set(row.statusId === 1);
      listVehiclesState.canSeeCrew.set(row.statusId !== 10);
      listVehiclesState.canConnectDevice.set((row.statusId == 10));
      listVehiclesState.canTransferDevice.set((row.statusId == 10 || row.statusId == 1));
      listVehiclesState.canDisconnectDevice.set((row.statusId == 1));
      listVehiclesState.canSetOutOfService.set((row.statusId == 1));
      listVehiclesState.canSetReturnToService.set((row.statusId == 11));
      listVehiclesState.plate.set(row.plate);
      listVehiclesState.labelCommander.set(row?.deviceType?.label_commander ?? 'Comandante');
      listVehiclesState.labelDriver.set(row?.deviceType?.label_driver ?? 'Motorista');
      listVehiclesState.labelPatrolmans.set(row?.deviceType?.label_patrolmans ?? 'Patrulheiro');
      listVehiclesState.labelKmInitial.set(row?.deviceType?.label_km_initial);
      listVehiclesState.dispatchGroup.set(row?.dispatchGroup)
      listVehiclesState.dispatchGroupId.set(row?.dispatchGroupId)
      listVehiclesState.trackingId.set(row?.trackingId)


    },
    returnToServiceVehicle: async () => {
      const deviceReturnToServiceId = listVehiclesState.deviceId.get();
      if (!deviceReturnToServiceId) {
        return
      };

      try {
        const response = await authFetch({
          url: '/device/back-to-service',
          method: 'POST',
          data: {
            'device_id': deviceReturnToServiceId,
          }
        })
        return response;
      } catch (error) {
        console.error(error);
      } finally {
        listVehiclesState.openReturnToServiceDialog.set(false);
      }
    },
    disconnectVehicle: async () => {
      const deviceClosureId = listVehiclesState.deviceId.get();
      if (!deviceClosureId) {
        return
      };

      try {
        const response = await authFetch({
          url: '/auth/logout/device/external',
          method: 'POST',
          data: {
            'device_id': deviceClosureId,
          }
        })
        return response;

      } catch (error) {
        console.error(error);
      } finally {
        listVehiclesState.openDisconnectDialog.set(false);
      }
    },
    getListVehiclesDisconnects: (param) => {
      listVehiclesMultipleDisconnect.merge([{
        device: param.device,
        dispatch_group_id: param.dispatch_group_id,
        error: param.error,
        imei: param.imei
      }])
    }
  }
  )
}

export default useListVehiclesSocket;