import React, { useEffect, useState } from "react"
import { FormControl, IconButton, InputAdornment, OutlinedInput } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'
import { useHookstate, type State } from '@hookstate/core'
import { useLang } from '~/hooks/useLang'

interface FielderTExtFieldProps {
  readonly onTextFieldChange: (value: string | null) => void;
  readonly currentText: string | null
  readonly label: string
}


export default function FilterTextField({ currentText, label, onTextFieldChange }: FielderTExtFieldProps) {
  const { translate } = useLang()
  const [value, setValue] = useState<string | null>(null)

  useEffect(() => {
    if (!currentText) {
      setValue('')
    }
  }, [currentText])


  return (
    <FormControl variant="standard" fullWidth >
      <OutlinedInput
        id={label}
        value={value}
        size="small"
        placeholder={translate(`${label}`)}
        onChange={(event) => {
          setValue(event.target.value)
        }}
        onBlur={() => onTextFieldChange(value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle clear filterText"
              onClick={() => {
                setValue('')
                onTextFieldChange(null)
              }}
              edge="end"
            >
              {value?.length ? (
                <CancelIcon fontSize="small" />
              ) : (
                ''
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl >
  )
}