import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IType from '../interfaces/IType';
import Layout from '~/components/Layout';
import { iconDeviceMarkersOptions } from '~/features/Map/components/MarkerGenerator/components/DeviceMarker/icon';


export default function Create() {
  const { register, handleSubmit, control, setValue: setFormValue } = useForm<IType>();
  const typeDevicesOptions = ['Car', 'Motorcycle', 'Helicopter', 'Bike', 'Boat', 'Agent', 'Crew', 'Horse', 'Scooter', 'Drone', 'Quadbike', 'Jetski']
  const fields = [
    { name: 'name', type: 'string' },
    { name: 'icon', type: 'select', options: typeDevicesOptions },
    { name: 'label_commander', type: 'string' },
    { name: 'label_driver', type: 'string' },
    { name: 'label_patrolmans', type: 'string' },
    { name: 'label_km_initial', type: 'string' },
  ];


  return (
    <Layout marginLayout={true}>
      <CrudCreate model={'device-type'}
        fields={fields}
        register={register}
        handleSubmit={handleSubmit}
        control={control}
        messageSuccess={'Device Type successfully created!'}
        title={'Add Device Type'}
        subheader={'Add Device Type'}
        setFormValue={setFormValue} />
    </Layout>
  )
}
