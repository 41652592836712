import React, { useState } from 'react'
authFetch
import {
  SubmitHandler,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import notify from '~/utils/notify'
import { CardActions, Grid } from '@mui/material'
import LoopIcon from '@mui/icons-material/Loop'
import { useLang } from '~/hooks/useLang'
import Layout from '~/components/Layout'
import ButtonForm from '~/components/ButtonForm'
import CrudGeneratorFields from '../CrudGeneratorFields'
import { AxiosError } from 'axios'
import { authFetch } from '~/services/fetch'
import { objectToSnakeCase } from '~/utils/format'
import InternalHeader from '../Layout/components/InternalHeader'
import InternalContent from '../Layout/components/InternalContent'
import { boolean } from 'zod'

export interface ICreateField {
  readonly name: string
  readonly type: string
  readonly model?: string
  readonly placeholder?: string
  readonly defaultChecked?: boolean
  readonly namePrimary?: string
  readonly nameSecondary?: string
  readonly urlFetch?: string
}
interface CrudCreateProps {
  readonly model: string
  readonly fields: readonly ICreateField[]
  readonly register: UseFormRegister<any>
  readonly handleSubmit: any
  readonly control: any
  readonly messageSuccess: string
  readonly title: string
  readonly subheader: string
  readonly setFormValue: UseFormSetValue<any>
  readonly afterSave?: (params) => void
  readonly getValues?: UseFormGetValues<any>
  readonly defaultValue?: any
}

export default function CrudCreate({
  model,
  fields,
  register,
  handleSubmit,
  control,
  messageSuccess,
  title,
  subheader,
  setFormValue,
  afterSave,
  getValues,
  defaultValue,
}: CrudCreateProps) {
  const { translate } = useLang()
  const history = useHistory()
  const [processing, setProcessing] = useState<number>(0)

  const onSubmit: SubmitHandler<any> = async (params) => {
    setProcessing(1)

    try {
      const response = await authFetch({
        url: `/${model}/store`,
        method: 'POST',
        data: objectToSnakeCase(params, defaultValue),
      })

      if (response.data) {
        notify({ message: `${translate(messageSuccess)}`, type: 'success' })

        if (afterSave) {
          return afterSave(response.data)
        } else {
          return setTimeout(() => history.push(`/${model}`), 1000)
        }
      }
    } catch (error) {
      const err = error as AxiosError
      throw (
        err.response?.data?.error || err.response?.data?.message || err.message
      )
    } finally {
      setProcessing(0)
    }
  }

  const handleCancelButton = () => {
    history.push(`/${model}`)
  }

  return (
    <>
      <InternalHeader title={translate(title)} />

      <InternalContent expandButton={false}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <CrudGeneratorFields
              fields={fields}
              register={register}
              control={control}
              setFormValue={setFormValue}
              getValues={getValues}
            />
          </Grid>

          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <ButtonForm type="cancel" onClick={handleCancelButton}>
              {translate('Cancel')}
            </ButtonForm>
            <ButtonForm type="submit" disabled={processing ? true : false}>
              <LoopIcon
                className="load-icon"
                sx={{ display: processing ? 'block' : 'none' }}
              />
              {processing ? translate('Saving') : translate('Save')}
            </ButtonForm>
          </CardActions>
        </form>
      </InternalContent>
    </>
  )
}
