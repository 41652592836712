import React from 'react';
import { useForm } from 'react-hook-form';
import CrudCreate from '~/components/CrudCreate';
import IDispatchGroup from '../interfaces/IDispatchGroup';
import Layout from '~/components/Layout';


export default function Create() {     
  const {register, handleSubmit, control, setValue:setFormValue} = useForm<IDispatchGroup>();
    const fields = [
      { name: 'name', type: 'string', required: true },
      { name: 'acronym', type: 'string', required: true },
      { name: 'agency_id', type: 'selectAutoComplete', model: 'agency', placeholder: 'Agency:name',required: true },
      { name: 'is_non_operational', type: 'switch', checked: false, placeholder: 'Is non operacional' },
      { name: 'area_ids', type: 'transferList', options: { firstListUrl: '', secondListUrl: '/area' }, model: 'area', placeholder: 'Associated Areas' },
  ];
  
    return(
      <Layout marginLayout={true}>
        <CrudCreate model={'dispatch/group'} 
        fields={fields} 
        register={register} 
        handleSubmit={handleSubmit} 
        control={control} 
        messageSuccess={'Dispatch Group successfully created!'}  
        title={'Add Dispatch Group'}  
        subheader={'Add a new Dispatch Group'}
        setFormValue={setFormValue}/>
      </Layout>
    )
}
