/* eslint-disable camelcase */
/* eslint-disable id-length */
import React from 'react'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import { LineChart } from '@mui/x-charts'
import { useLang } from '~/hooks/useLang'

export default function LineChartMui({
  data,
  title,
  subtitle = '',
  sxFont = {},
  colorBar = 'primary',
  typeTotal = '',
  scaleType = 'band',
  hasOtherLabel = false,
  Ytype = 'number',
  marginLeft = 70,
  tooltip = '',
}) {
  const { translate } = useLang()

  const getColor = {
    primary: '#6395FA',
    secondary: '#59BD8E',
    tertiary: '#EDAE49',
  }

  const xFieldTreatament = data.map((element) => {
    return String(element.item)
  })

  const dataTreatament = data.map((element) => {
    return element.value
  })

  return (
    <Card variant="outlined" sx={{ height: 600, overflowX: 'auto' }}>
      <CardContent sx={{ height: '80%' }}>
        <Typography sx={{ fontWeight: 'bold', ...sxFont }}>
          {translate(title)}
        </Typography>
        {subtitle && (
          <Typography variant="subtitle2" marginBottom={2} marginTop={1}>
            {translate(subtitle)}
{' '}
            {typeTotal &&
              `| ${translate('Total of')} ${translate(typeTotal)}: ${
                data.length
              }`}
          </Typography>
        )}
        {data.length ? (
          <Grid>
            <LineChart
              series={[{ curve: 'linear', data: dataTreatament
              }]}
              xAxis={[
                {
                 scaleType: 'point',
                  data: xFieldTreatament,
                  tickPlacement: 'middle',
                  tickLabelPlacement: 'tick',
                  tickLabelStyle: {
                    fontSize: xFieldTreatament.length >= 10 ? 11 : 12,
                    angle: 40,
                    textAnchor: 'start',
                  },
                }
              ]}

              height={500}
              skipAnimation
              grid={{ horizontal: true }}
              margin={{ top: 25, left: marginLeft, bottom: 100 }}
              leftAxis={{ labelStyle: { fontSize: 10 } }}
            />
          </Grid>
        ) : (
          <Grid item sx={{ height: '100%' }}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                height: '100%',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                border: 'none',
              }}
            >
              <CardContent>
                <Typography>
                {translate('No data available')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
      </CardContent>
    </Card>
  )
}
