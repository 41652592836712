import { authFetch } from "~/services/fetch";

export async function makeRequestToGetTotalQualifiersSearchesByUser({
  startDate,
  finishDate,
  contactCenterId,
  userId,
  exportCSV = false,
}) {
  try {
    const response = await authFetch({
      url: `/report/total-qualifiers-searches-by-user`,
      method: 'POST',
      data: {
        start_date: startDate,
        finish_date: finishDate,
        contact_center_id: contactCenterId,
        user_id: userId,
        export_csv: exportCSV
      }
    });

    return response.data
  } catch (error) {
    console.error('ERROR GENERATE REPORT', error)
    throw error
  }
}