import React, { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useHistory } from 'react-router-dom';
import { MenuProps } from '~/components/SidebarMenu/interfaces';
import { useLang } from '~/hooks/useLang';
import { Collapse, List } from '@mui/material';
import userUserState from '~/features/User/stores/UserState';
import useSystemState from '~/features/System/stores/SystemState';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// eslint-disable-next-line max-lines-per-function
export default function ReportByDevices({ handleMenuClick }: MenuProps) {
  const { translate } = useLang();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { verifyPermission } = userUserState();
  const { systemConfig } = useSystemState()

  const handleClickOpen = () => {
    setOpen((old) => !old);
  };

  return (
    <>
      <Tooltip title={translate('Generate report') + " " + translate('Devices')} placement="right">
        <ListItemButton onClick={handleClickOpen}>
          <ListItemText primary={translate('Devices')} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Tooltip>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {
            verifyPermission(['reports_generator']) && (
              <>
                {verifyPermission(['report_devices_list']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('List of Devices')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/devices-dispatch-group-agency");
                      }}
                    >
                      <ListItemText primary={translate('List of Devices')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_connection_logs']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Connections Logs')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/connection-logs");
                      }}
                    >
                      <ListItemText primary={translate('Connections Logs')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_devices_by_status']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('List of devices by status on a date')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/devices-by-status");
                      }}
                    >
                      <ListItemText primary={translate('List of devices by status on a date')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_device_services']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Device Services')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/device-services");
                      }}
                    >
                      <ListItemText primary={translate('Device Services')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_devices_x_dispatches']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Device status x Pending occurrences')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/devices-vs-dispatches");
                      }}
                    >
                      <ListItemText primary={translate('Device status x Pending occurrences')} />
                    </ListItemButton>
                  </Tooltip>
                )}

                {verifyPermission(['report_connected_devices']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Total connected devices')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/connected-devices");
                      }}
                    >
                      <ListItemText primary={translate('Total connected devices')} />
                    </ListItemButton>
                  </Tooltip>
                )}
                {verifyPermission(['report_device_timeline']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Device timeline')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/device-timeline");
                      }}
                    >
                      <ListItemText primary={translate('Device timeline')} />
                    </ListItemButton>
                  </Tooltip>
                )}
                {verifyPermission(['report_device_out_of_service_productivity']) && (
                  <Tooltip
                    title={`${translate('Generate report')}` + ' ' + `${translate('Device productivity: Out of service status')}`}
                    placement="right"
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      onClick={() => {
                        handleMenuClick('reports');
                        history.push("/report/out-of-service-productivity");
                      }}
                    >
                      <ListItemText primary={translate('Device productivity: Out of service status')} />
                    </ListItemButton>
                  </Tooltip>
                )}
                {/* {verifyPermission(['report_device_on_mission_productivity']) &&
                  Boolean(Number((systemConfig().enableOnMissionStatusOfDevice.get()))) && (
                    <Tooltip
                      title={`${translate('Generate report')}` + ' ' + `${translate('Device productivity: On mission statu')}`}
                      placement="right"
                    >
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={() => {
                          handleMenuClick('reports');
                          history.push("/report/on-mission-productivity");
                        }}
                      >
                        <ListItemText primary={translate('Device productivity: On mission status')} />
                      </ListItemButton>
                    </Tooltip>
                  )} */}
              </>
            )
          }
        </List>
      </Collapse>
    </>
  )
}