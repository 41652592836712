/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Box, Button, Card, CardHeader, Collapse, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, InputAdornment, OutlinedInput, Switch } from '@mui/material';
import ErrorFallback from '~/components/ErrorFallback';
import Layout from '~/components/Layout';
import Loading from '~/components/Loading';
import { useLang } from '~/hooks/useLang';
import notify from '~/utils/notify';
import { authFetch } from '~/services/fetch';
import useReportState from '../../../stores/ReportState';
import { downloadReportCSV } from '../../../services/report';
import { useHookstate } from '@hookstate/core';
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod';
import FilterAutocompleteDispatchGroup from '~/components/FilterAutocompleteDispatchGroup';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import GenerateReportButton from '../../../components/GenerateReportButton';
import ExportButtonCSV from '../../../components/ButtonExportCSV';
import FilterAutocompleteDevices from '~/components/FilterAutocompleteDevices';
import FilterSwitch from '../../ReportOccurrence/SpecificFilters/FilterSwitchGroupLocation';
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies';
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups';
import CancelIcon from '@mui/icons-material/Cancel';
import FilterKeyword from '../../../components/FilterKeyword';
import FilterAutocompleteFetch from '~/components/FilterAutocompleteFetch';
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet';
import { makeRequestToGetConnectionLogs } from './services';

export default function ConnectionLogs() {
  const { translate } = useLang();
  const isLoading = useHookstate<boolean>(false);
  const expandFilterSearch = useHookstate<boolean>(true);
  const startDate = useHookstate<string | null>(null);
  const finishDate = useHookstate<string | null>(null);
  const groupsId = useHookstate<readonly number[]>([]);
  const agencyIds = useHookstate<readonly number[]>([]);
  const devicesIds = useHookstate<readonly number[]>([]);
  const labelButton = useHookstate('')
  const lockFields = useHookstate<boolean>(false);
  const detailedReport = useHookstate<boolean>(false)
  const period = useHookstate('')
  const reportName = 'connection-logs'
  const {
    reportData,
    loadingReport,
    dispatchGroups: {
      dispatchGroup
    }
  } = useReportState();
  const keyWords = useHookstate('');
  const result = useHookstate(['']);
  const userId = useHookstate<number | null>(null)
  const resetValeus = useHookstate(false)
  const groupSetId = useHookstate<number | null>(null)

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  const makeKeyWordsRequest = () => {

    if (keyWords.get().split(';').length == 1 && keyWords.get().split(';')[0] == '') {
      result.set([])
    } else {
      result.set(keyWords.get().split(';'))
    }

  }

  useEffect(() => {
    const getDispatchGroups = async () => {
      authFetch({
        url: '/station/dispatch-groups/me',
        method: 'GET',
        data: {}
      }).then(response => {
        dispatchGroup.options.merge(response.data)
      })
    }

    getDispatchGroups();
  }, [])

  async function generateReport(exportCSV = false) {
    makeKeyWordsRequest()
    if (startDate && finishDate) {
      isLoading.set(true)
      !exportCSV && loadingReport().set(true)
      loadingReport().set(true)
      const data = await makeRequestToGetConnectionLogs({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        deviceIds: devicesIds.get(),
        detailedReport: detailedReport.get(),
        agencyIds: agencyIds.get(),
        dispatchGroupsId: groupsId.get(),
        keyWords: result.get(),
        userId: userId.get(),
        groupSetId: groupSetId.get(),
        exportCSV: exportCSV
      }).finally(() => {
        loadingReport().set(false)
        isLoading.set(false)
      })

      if (exportCSV) {
        downloadReportCSV(`${translate(reportName)}-${new Date().getTime()}.csv`, data)
      } else {
        setTimeout(() => {
          reportData().set(data)
        })
        window.open(`/report/result/${reportName}`)
      }
    } else {
      notify({ message: translate('Initial and final date are mandatory'), type: 'error' })
    }
  }

  const clearFilters = () => {
    keyWords.set('')
    agencyIds.set([])
    groupSetId.set(null)
    groupsId.set([])
    devicesIds.set([])
    userId.set(null)
    period.set('')
    detailedReport.set(false)
    resetValeus.set(!resetValeus.get())
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true} >
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader title={`${translate('Report')}: ${translate(`Connections Logs`)}`} />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader title={`${translate('Filters')}`}
            avatar={(
              <IconButton
                onClick={() => expandFilterSearch.set(!expandFilterSearch.get())}
                sx={{ display: 'flex', justifyContent: 'end', marginRight: 1, padding: 0 }}>
                {expandFilterSearch.value ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
              </IconButton>
            )}
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid
              container xs={12}
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                padding: 2,
              }}
            >
              {detailedReport.get() &&
                <Grid item xs={5} sx={{ pl: 1, pt: 1 }}>
                  <FilterKeyword keyWords={keyWords} label="Keywords in observation, acting or tracking" />
                </Grid>
              }
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    disabled={lockFields.get()}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => {
                      labelButton.set(translate('Search'))
                      agencyIds.set(agency)
                    }} />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchGroupSet
                    disabled={lockFields.get()}
                    dispatchGroupSetId={groupSetId?.get() ?? undefined}
                    onGroupDispatchSetChange={(group) => {
                      groupSetId?.set(group)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get() ?? undefined}
                    dispatcheGroups={groupsId.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupsId.set(group)
                      labelButton.set(translate('Search'))
                    }}
                    groupSetId={groupSetId.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDevices
                    devicesIds={devicesIds.get()}
                    onDevicesChange={(device) => {
                      devicesIds.set(device)
                    }}
                    disabled={lockFields.get()}
                    groupDispatchId={groupsId.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteFetch
                    label={'User'}
                    urlFetch={'/user/get'}
                    size={"small"}
                    disabled={lockFields.get()}
                    onFetchChange={(user) => user ? userId.set(user) : null}
                    resetValues={resetValeus.get()}
                  />
                </Grid>
                <Grid container direction={'row'}>
                  <FilterSelectPeriod
                    disabled={lockFields.get()}
                    onStartDateChange={(value) => startDate.set(value?.toJSON() ?? null)}
                    onFinishDateChange={(value) => finishDate.set(value?.toJSON() ?? null)}
                    onPeriodChange={(value) => { period.set(value) }}
                    period={period.get()}
                    required
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterSwitch
                    label={translate('Detailed report')}
                    onCheckedChange={() => {
                      detailedReport.set(!detailedReport.value)
                      keyWords.set('')
                      result.set([''])
                    }}
                    disabled={lockFields.get()}
                    checked={detailedReport.get()} />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2
                }}>
                <Button
                  variant="outlined"
                  onClick={() => clearFilters()}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={() => generateReport(true)}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={generateReport}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary >
  )
}