import React from "react"
import { Downgraded } from "@hookstate/core"
import SearchIcon from '@mui/icons-material/Search';
import { Button, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material"
import FilterAutocompleteLocationAccuracy from "~/components/FilterAutocompleteLocationAccuracy"
import { useLang } from "~/hooks/useLang"

export default function FilterDispatchHotMap({expandFilterSearch, locationAccuracy, refreshHotMap, loading}) {
  const { translate } = useLang()

    return (
        <Grid
         xs={12} 
         hidden={!expandFilterSearch} 
         flex={1} 
         container 
         spacing={2}
         marginTop={1}
         alignItems={'end'}>
          <Grid item xs={2} >
            <FilterAutocompleteLocationAccuracy
              onAccuracyChange={(accuracy) => locationAccuracy.set(accuracy)}
              locationAccuracy={locationAccuracy.attach(Downgraded).get()}
            />
          </Grid>
          <Grid item xs={2} marginBottom={-3}>
          <Button
            variant="text"
            sx={{
              height: '40px',
              textTransform: 'none',
              textDecorationLine: 'underline',
              color: 'text.primary',
              ':hover': {
                bgcolor: 'transparent',
                color: 'primary.main'
              }
            }}
            onClick={() => {
              locationAccuracy.set([])
              refreshHotMap.set(!refreshHotMap.get())
            }}>
              {translate('Clear Filters')}
            </Button>
            <Tooltip title={translate('Search')} placement="bottom">
              <IconButton
                onClick={() => refreshHotMap.set(!refreshHotMap.get())}
                disabled={loading.get()}
              >
                {loading.get() ? <CircularProgress size={20} /> : <SearchIcon />}
              </IconButton>
            </Tooltip>
            
          </Grid>
      </Grid>
    )
}