/* eslint-disable max-lines-per-function */
/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
  Button,
  Card,
  CardHeader,
  Collapse,
  Grid,
  IconButton,
} from '@mui/material'
import ErrorFallback from '~/components/ErrorFallback'
import Layout from '~/components/Layout'
import { useLang } from '~/hooks/useLang'
import notify from '~/utils/notify'
import useReportState from '../../../stores/ReportState'
import { useHookstate } from '@hookstate/core'
import FilterSelectPeriod from '~/components/FilterAutocompletePeriod'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import FilterAutocompleteContactCenter from '~/components/FilterAutocompleteContactCenter'
import ExportButtonCSV from '~/features/Report/components/ButtonExportCSV'
import GenerateReportButton from '../../../components/GenerateReportButton'
import FilterAutocompleteOperation from '~/components/FilterAutocompleteOperation'
import FilterAutocompleteAgencies from '~/components/FilterAutocompleteAgencies'
import FilterAutocompleteDispatchGroupSet from '~/components/FilterAutocompleteDispatchGroupSet'
import FilterAutocompleteDevice from '~/components/FilterAutocompleteDevice'
import FilterAutocompleteDispatchsGroups from '~/components/FilterAutocompleteDispatchsGroups'
import { makeRequestToGetOutOfServiceProductivity } from './services'
import { downloadReportCSV } from '~/features/Report/services/report'
import FilterAutocompleteOperationUnit from '~/components/FilterAutocompleteOperationUnit'


export default function OutOfServiceProductivity() {
  const expandFilterSearch = useHookstate<boolean>(true)
  const { translate } = useLang()
  const isLoading = useHookstate<boolean>(false)
  const lockFields = useHookstate<boolean>(false)
  const period = useHookstate('')
  const startDate = useHookstate<string | null>(null)
  const finishDate = useHookstate<string | null>(null)
  const contactCenterId = useHookstate<number | null>(null)
  const operationUnitId = useHookstate<number | null>(null)
  const agencyIds = useHookstate<readonly number[]>([])
  const groupSetId = useHookstate<number | null>(null)
  const deviceId = useHookstate<number | null>(null)
  const groupIds = useHookstate<readonly number[]>([])
  const reportName = 'out-of-service-productivity'

  const { reportData, loadingReport } = useReportState()

  useEffect(() => {
    document.title = translate('Reports - Dispatch System')
  }, [])

  async function generateReport(exportCSV = false) {
    if (startDate && finishDate) {
      isLoading.set(true)
      !exportCSV && loadingReport().set(true)
      const data = await makeRequestToGetOutOfServiceProductivity({
        startDate: startDate.get(),
        finishDate: finishDate.get(),
        agencyIds: agencyIds.get(),
        deviceId: deviceId.get(),
        dispatchGroupsId: groupIds.get(),
        groupSetId: groupSetId.get(),
        operationUnitId: operationUnitId.get(),
        exportCSV: exportCSV
      }).finally(() => {
        loadingReport().set(false)
        isLoading.set(false)
      })

      if (exportCSV) {
        downloadReportCSV(
          `${translate(reportName)}-${new Date().getTime()}.csv`,
          data
        )
      } else {
        setTimeout(() => {
          reportData().set(data)
        })
        window.open(`/report/result/${reportName}`)
      }
    } else {
      notify({
        message: translate('Initial and final date are mandatory'),
        type: 'error',
      })
    }
  }

  const clearFilters = () => {
    period.set('')
    operationUnitId.set(null)
    groupIds.set([])
    groupSetId.set(null)
    deviceId.set(null)
    agencyIds.set([])
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Layout marginLayout={true}>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <Grid container xs={12}>
            <Grid item>
              <CardHeader
                title={`${translate('Report')}: ${translate(
                  `Device productivity: Out of service status`
                )}`}
              />
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ boxShadow: 4, marginBottom: 2 }}>
          <CardHeader
            title={`${translate('Filters')}`}
            avatar={
              <IconButton
                onClick={() =>
                  expandFilterSearch.set(!expandFilterSearch.get())
                }
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginRight: 1,
                  padding: 0,
                }}
              >
                {expandFilterSearch.value ? (
                  <ExpandLessIcon />
                ) : (
                  <ExpandMoreIcon />
                )}
              </IconButton>
            }
          />
          <Collapse in={expandFilterSearch.get()}>
            <Grid container direction={'column'} rowGap={2} padding={2}>
              <Grid container direction={'row'} rowGap={2}>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDevice
                    deviceId={deviceId.get()}
                    onDeviceChange={(device) => {
                      deviceId.set(device)
                    }}
                    disabled={lockFields.get()}
                    groupDispatchs={groupIds.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteOperationUnit
                    //  disabled={lockFields}
                    onOperationUnitChange={(operationUnit) => {
                      operationUnitId.set(operationUnit)
                      deviceId.set(null)
                    }}
                    operationUnitId={operationUnitId.get()}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteAgencies
                    // disabled={lockFields}
                    agenciesId={agencyIds.get()}
                    onAgenciesChange={(agency) => {
                      // labelButton.set(translate('Search'))
                      agencyIds.set(agency)
                      groupSetId.set(null)
                    }} />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchGroupSet
                    // disabled={lockFields}
                    dispatchGroupSetId={groupSetId?.get() ?? undefined}
                    onGroupDispatchSetChange={(group) => {
                      groupSetId?.set(group)
                    }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pl: 1, pt: 1 }}>
                  <FilterAutocompleteDispatchsGroups
                    disabled={lockFields.get()}
                    agencyId={agencyIds.get() ?? undefined}
                    dispatcheGroups={groupIds.get() ?? undefined}
                    onGroupDispatchChange={(group) => {
                      groupIds.set(group)
                      deviceId.set(null)
                      // labelButton.set(translate('Search'))
                    }}
                    groupSetId={groupSetId.get()}
                  />
                </Grid>
                <Grid container direction={'row'}>
                  <FilterSelectPeriod
                    disabled={lockFields.get()}
                    onStartDateChange={(value) =>
                      startDate.set(value?.toJSON() ?? null)
                    }
                    onFinishDateChange={(value) =>
                      finishDate.set(value?.toJSON() ?? null)
                    }
                    onPeriodChange={(value) => {
                      period.set(value)
                    }}
                    period={period.get()}
                    required
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 2,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => clearFilters()}
                  sx={{ height: '40px' }}
                >
                  {translate('Clear Filters')}
                </Button>
                <ExportButtonCSV
                  isLoading={isLoading.get()}
                  dataToExport={() => generateReport(true)}
                />
                <GenerateReportButton
                  isLoading={isLoading.get()}
                  makeReport={() => generateReport()}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Card>
      </Layout>
    </ErrorBoundary >
  )
}
